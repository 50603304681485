$icomoon-font-family: "icons" !default;
$icomoon-font-path: "fonts" !default;

$td-icon-balance: "\e9d1";
$td-icon-dayFinances: "\e9d2";
$td-icon-dynamicPreformance: "\e9d3";
$td-icon-easySafety: "\e9d4";
$td-icon-exit: "\e9de";
$td-icon-growth: "\e9d5";
$td-icon-growthIncome: "\e9d6";
$td-icon-income: "\e9d7";
$td-icon-incomeGrowth: "\e9d8";
$td-icon-mixedPortfolio: "\e9e0";
$td-icon-na: "\e9d9";
$td-icon-noSaving: "\e9da";
$td-icon-overdraftProtection: "\e9df";
$td-icon-savePortion: "\e9db";
$td-icon-saveWhenever: "\e9dc";
$td-icon-slowSteady: "\e9dd";
$td-icon-AccidentSickness: "\e901";
$td-icon-FileAClaim: "\e91d";
$td-icon-GetAQuote: "\e91f";
$td-icon-GetAQuote02: "\e91e";
$td-icon-IRA: "\e9b7";
$td-icon-Login: "\e92c";
$td-icon-MBNARewards: "\e9c8";
$td-icon-POSSolutions: "\ea37";
$td-icon-RDSP: "\ea32";
$td-icon-RIF: "\ea33";
$td-icon-TD: "\e942";
$td-icon-TDApp: "\e9ff";
$td-icon-TDEasyWeb: "\ea00";
$td-icon-TDShield: "\e9ed";
$td-icon-TDdirectInvesting: "\e9ae";
$td-icon-TDwealth: "\e9af";
$td-icon-accidentReports: "\e900";
$td-icon-accounts: "\e979";
$td-icon-activateCard: "\e9ec";
$td-icon-aeroplan: "\e9b8";
$td-icon-aeroplan2: "\ea1e";
$td-icon-aeroplanRondel: "\ea2d";
$td-icon-agriculture: "\ea34";
$td-icon-all: "\e983";
$td-icon-allCalculatorTools: "\e9a0";
$td-icon-allProducts: "\e981";
$td-icon-apple: "\ea3a";
$td-icon-applyForFunding: "\e9c5";
$td-icon-approved: "\e902";
$td-icon-arrowDown2: "\ea29";
$td-icon-arrowLeft: "\e903";
$td-icon-arrowLeft2: "\ea2a";
$td-icon-arrowRight: "\e904";
$td-icon-arrowRight2: "\ea2b";
$td-icon-arrowUp2: "\ea2c";
$td-icon-atm: "\e905";
$td-icon-attractNewCustomers: "\ea19";
$td-icon-atv: "\ea3d";
$td-icon-auto: "\e9ee";
$td-icon-avatar: "\e999";
$td-icon-balanceTransfer: "\e9f8";
$td-icon-banking: "\e907";
$td-icon-bath: "\e964";
$td-icon-bed: "\e965";
$td-icon-bicycle: "\e908";
$td-icon-bookAppointment: "\e909";
$td-icon-bookAtBranch: "\e90a";
$td-icon-borrowing: "\e90b";
$td-icon-borrowingSolutions: "\e9e4";
$td-icon-branch: "\e90c";
$td-icon-branchATM: "\e90d";
$td-icon-business: "\e99a";
$td-icon-businessChecklist: "\ea0a";
$td-icon-businessOwner: "\e90e";
$td-icon-cantFindTopic: "\e90f";
$td-icon-car: "\e910";
$td-icon-careers: "\e9c9";
$td-icon-cashBack: "\e9b9";
$td-icon-cashFinances: "\e9e5";
$td-icon-cashflow: "\ea0b";
$td-icon-cashflowImprovement: "\ea0c";
$td-icon-certificateDeposit: "\e9d0";
$td-icon-chat: "\e911";
$td-icon-checkMark: "\e912";
$td-icon-chequeing: "\e98e";
$td-icon-chequeing2: "\e9ef";
$td-icon-circle: "\e913";
$td-icon-claims: "\e914";
$td-icon-clearButton: "\ea2e";
$td-icon-close: "\e915";
$td-icon-coinsHigh: "\e966";
$td-icon-coinsLow: "\e967";
$td-icon-coinsMiddle: "\e968";
$td-icon-collapse: "\e969";
$td-icon-communityNonprofit: "\e9fe";
$td-icon-condo: "\e916";
$td-icon-contactUs: "\e917";
$td-icon-corporateResponsibility: "\e9ca";
$td-icon-corporation: "\ea28";
$td-icon-coverage: "\e918";
$td-icon-coverageExtra: "\e919";
$td-icon-coverageMandatory: "\e91a";
$td-icon-coverageModify: "\e91b";
$td-icon-coverageSpecial: "\e91c";
$td-icon-creditProtection: "\e920";
$td-icon-creditcards: "\e921";
$td-icon-customers: "\e9cb";
$td-icon-dashboard: "\e9b0";
$td-icon-debt-en: "\e9f1";
$td-icon-debt-fr: "\e9f3";
$td-icon-debtConsolidation: "\e988";
$td-icon-delete: "\e9a1";
$td-icon-discount: "\e922";
$td-icon-distributionGuide: "\e923";
$td-icon-downCaret: "\e924";
$td-icon-download: "\e98f";
$td-icon-downloadBusinessPlanTemplate: "\ea0d";
$td-icon-downloadCertificate: "\e925";
$td-icon-earthquake: "\e926";
$td-icon-economics: "\e9cc";
$td-icon-edit2: "\e978";
$td-icon-edits: "\e990";
$td-icon-email: "\e9b6";
$td-icon-employees: "\ea04";
$td-icon-ensureCompliance: "\ea0e";
$td-icon-equities: "\ea1f";
$td-icon-error: "\e927";
$td-icon-etf: "\ea20";
$td-icon-etf-fr: "\ea21";
$td-icon-exchangeRateForCash: "\e96a";
$td-icon-exchangeRateForNonCash: "\e96b";
$td-icon-expand: "\e96c";
$td-icon-expandedBusinessServices: "\ea24";
$td-icon-facebook: "\e928";
$td-icon-faqs: "\e929";
$td-icon-feedback: "\e92a";
$td-icon-filter: "\e9a5";
$td-icon-financeSmallBusiness: "\ea0f";
$td-icon-financialPlanning: "\ea3c";
$td-icon-findAcreditcard: "\e97b";
$td-icon-findTaxEfficiencies: "\e9a9";
$td-icon-firstVehicle: "\e9f5";
$td-icon-fixedIncome: "\ea22";
$td-icon-flexibleMortgageFeatures: "\e9a2";
$td-icon-forms: "\e9f9";
$td-icon-franchise: "\ea35";
$td-icon-fraud: "\e9f0";
$td-icon-generateIncome: "\e9aa";
$td-icon-geolocation: "\e92b";
$td-icon-gicTermDeposits: "\e9c1";
$td-icon-giftcardLoyalty: "\ea38";
$td-icon-government: "\ea05";
$td-icon-graduationCap: "\ea3b";
$td-icon-growMyMoney: "\e97c";
$td-icon-growYourBusiness: "\e9e6";
$td-icon-growingFamily: "\e92d";
$td-icon-growingFamily2: "\ea40";
$td-icon-hamburger: "\e92e";
$td-icon-handicap: "\e92f";
$td-icon-healthCarePractitioners: "\ea1a";
$td-icon-help: "\e930";
$td-icon-home: "\e931";
$td-icon-home2: "\ea41";
$td-icon-homeEquityFlexline: "\e9a3";
$td-icon-homepage: "\e932";
$td-icon-hours: "\e933";
$td-icon-house: "\e96d";
$td-icon-howTo: "\e984";
$td-icon-idea: "\ea10";
$td-icon-identityTheft: "\e934";
$td-icon-improveSales: "\ea1b";
$td-icon-inbox: "\e935";
$td-icon-increaseBusinessProductivity: "\ea25";
$td-icon-information: "\e936";
$td-icon-insights: "\e9a6";
$td-icon-instagram: "\e985";
$td-icon-insurance: "\e986";
$td-icon-international: "\e991";
$td-icon-investTradeOnline: "\e9e3";
$td-icon-investing: "\e937";
$td-icon-investingPlatforms: "\e9fa";
$td-icon-investmentTransfers: "\e9b1";
$td-icon-investmentTypes: "\ea2f";
$td-icon-investorRelations: "\e9cd";
$td-icon-languagesSpoken: "\e938";
$td-icon-leftCaret: "\e939";
$td-icon-life: "\ea3f";
$td-icon-lineOfCredit: "\e9ba";
$td-icon-linkedin: "\e987";
$td-icon-loanCalculator: "\ea11";
$td-icon-location: "\e93b";
$td-icon-location-coverage: "\e93c";
$td-icon-locationFeatures: "\e93d";
$td-icon-logout: "\e93e";
$td-icon-lowInterest: "\e9bb";
$td-icon-maintenance: "\e93f";
$td-icon-makeAMajorPurchase: "\e9ab";
$td-icon-manageBusiness: "\ea06";
$td-icon-manageMoney: "\e9fb";
$td-icon-managingEmployees: "\ea26";
$td-icon-mapMarker: "\e940";
$td-icon-mapMarkerFilled: "\e941";
$td-icon-marine: "\e9bc";
$td-icon-mobilePhone: "\ea30";
$td-icon-mortgages: "\e943";
$td-icon-motorHome: "\e944";
$td-icon-motorcycle: "\e945";
$td-icon-mutualFunds: "\e9c2";
$td-icon-myAdvantage: "\ea44";
$td-icon-myPolicies: "\e947";
$td-icon-myTD: "\e989";
$td-icon-navigateVolatility: "\e9ac";
$td-icon-newCouple: "\e948";
$td-icon-newCouple2: "\ea42";
$td-icon-newHome: "\e949";
$td-icon-newPage: "\e9f7";
$td-icon-newRevenueStreams: "\ea1c";
$td-icon-newToCanada: "\e9c0";
$td-icon-noFee: "\e9bd";
$td-icon-offers: "\e9c4";
$td-icon-oilDamage: "\e94a";
$td-icon-onlinePreapproval: "\e9cf";
$td-icon-onlineSalesSolutions: "\ea39";
$td-icon-options: "\ea23";
$td-icon-orderByPhone: "\e96e";
$td-icon-orderCashOnline: "\e96f";
$td-icon-orderStatus: "\e99b";
$td-icon-otherServices: "\ea36";
$td-icon-outlinedCircleClose: "\ea31";
$td-icon-overlayLink: "\e997";
$td-icon-payBillOnline: "\ea07";
$td-icon-payBills: "\e97d";
$td-icon-payUSBill: "\e970";
$td-icon-paymentsTransfers: "\ea01";
$td-icon-pending: "\e992";
$td-icon-person: "\ea08";
$td-icon-personal: "\e94b";
$td-icon-pinterest: "\e998";
$td-icon-preciousMetals: "\e9c3";
$td-icon-preferredAutoVendors: "\e94c";
$td-icon-prepaidCard: "\e9be";
$td-icon-priceTag: "\e9fc";
$td-icon-printer: "\e975";
$td-icon-products: "\e94d";
$td-icon-productsAndServices: "\ea12";
$td-icon-profitIncrease: "\ea13";
$td-icon-question: "\e94e";
$td-icon-quote: "\e9b2";
$td-icon-readyCommitment: "\e9c6";
$td-icon-registeredAccounts: "\e9b3";
$td-icon-registrationPasswords: "\ea02";
$td-icon-renters: "\ea3e";
$td-icon-renters-fr: "\e9e7";
$td-icon-replacementCard: "\e9eb";
$td-icon-requestCertificate: "\e950";
$td-icon-resourceCentre: "\ea14";
$td-icon-retirementPlanning: "\e98d";
$td-icon-rightCaret: "\e951";
$td-icon-saveForSchool: "\e9ad";
$td-icon-savingAndInvesting: "\e98a";
$td-icon-savings: "\e952";
$td-icon-search: "\e953";
$td-icon-secondVehicle: "\e9f6";
$td-icon-secureBtn: "\e954";
$td-icon-security: "\ea27";
$td-icon-selectADate: "\e993";
$td-icon-sendFeedback: "\e982";
$td-icon-sendMoney: "\e97e";
$td-icon-sendMoneyInternationally: "\e971";
$td-icon-share: "\e976";
$td-icon-shopping: "\e99c";
$td-icon-smallBusiness: "\e955";
$td-icon-snowmobile: "\e956";
$td-icon-solutions: "\e98c";
$td-icon-star: "\e957";
$td-icon-startClaim: "\e958";
$td-icon-startOver: "\e977";
$td-icon-startingUp: "\e9e8";
$td-icon-startingUp-fr: "\e9e9";
$td-icon-startup: "\ea15";
$td-icon-student: "\e98b";
$td-icon-successionPlanning: "\e9ea";
$td-icon-superlock: "\e9a7";
$td-icon-swapLocations: "\e959";
$td-icon-switchToTDMortgage: "\e9a4";
$td-icon-takeAPhoto: "\e994";
$td-icon-takeAPhotoOutlined: "\ea43";
$td-icon-taxFreeAccounts: "\e9b4";
$td-icon-tdInsuranceCard: "\e95a";
$td-icon-tdRewards: "\e97a";
$td-icon-toOurCustomers: "\e9ce";
$td-icon-todaysRates: "\e97f";
$td-icon-tools: "\e9a8";
$td-icon-tooltip: "\e972";
$td-icon-trade: "\e980";
$td-icon-tradingAccounts: "\e9b5";
$td-icon-transfer: "\e973";
$td-icon-transit: "\e95b";
$td-icon-travel: "\e95c";
$td-icon-travelRewards: "\e9bf";
$td-icon-truck: "\e9f2";
$td-icon-tutorials: "\ea03";
$td-icon-twitter: "\e95d";
$td-icon-unexpectedExpenses: "\e9f4";
$td-icon-unsureHome: "\e974";
$td-icon-upCaret: "\e95e";
$td-icon-usDollar: "\e995";
$td-icon-vacation: "\e99d";
$td-icon-vendor: "\ea09";
$td-icon-videoPlayButton: "\ea16";
$td-icon-videoPlayButtonCircle: "\ea17";
$td-icon-walking: "\e95f";
$td-icon-wallet: "\ea45";
$td-icon-warning: "\e960";
$td-icon-watchlists: "\e99e";
$td-icon-waterDamage: "\e961";
$td-icon-wealthAdvise: "\e9e2";
$td-icon-webBroker: "\e9fd";
$td-icon-whatsNew: "\e9e1";
$td-icon-wifi: "\e99f";
$td-icon-womenAndHealth: "\ea1d";
$td-icon-workplace: "\e9c7";
$td-icon-writeBusinessPlan: "\ea18";
$td-icon-youngAndSingle: "\e962";
$td-icon-youthAccount: "\e996";
$td-icon-youtube: "\e963";

