// Form elements in the Emerald Standards core

// ------ form-group related rules START ------//
.form-group {
  // input group - prefix & postfix
  .input-group {
    position: relative;

    .input-group-prepend,
    .input-group-append {
      display: block;
      position: absolute;
      top: 10px;
    }

    .input-group-prepend {
      left: 15px;
    }

    .input-group-append {
      right: 15px;
    }

    .input-group-text {
      border: none;
      padding: 0;
      font-family: $td-form-element-font-family;
    }

    // increase padding to accomodate for prefix & postfix addon
    .form-control {
      position: static;
      padding-left: 30px;
      padding-right: 35px;

      &:focus {
        z-index: auto;  // remove z-index so focusing on input won't hide prefix/postfix text
      }
    }
  }
  // custom class to add padding to .form-group, we avoid to directly change padding on .form-group to avoid regression
  &.td-form-group-padding {
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 0;     // remove Bootstrap's default bottom margin when the custom td-form-group-padding class is used
  }
}
// ------ form-group related rules END ------//

// ------ Base Forms & Textbox START --------//
.form-control {
  // disabled
  &:disabled,
  &[readonly]{
    color: $td-form-element-form-control-disabled-color;
    border-color: $td-form-element-form-control-disabled-border-color;
    background-color: $td-form-element-form-control-disabled-background-color;

    // disabled Placeholder
    &:-moz-placeholder {
      color: $td-form-element-form-control-disabled-color;
    }
    &::-moz-placeholder {
      color: $td-form-element-form-control-disabled-color;
    }
    &:-ms-input-placeholder {
      color: $td-form-element-form-control-disabled-color;
    }
    &::-ms-input-placeholder{
      color: $td-form-element-form-control-disabled-color;
    }
    &::-webkit-input-placeholder {
      color: $td-form-element-form-control-disabled-color;
    }
  }
  height: 42px;
  border-width: 1px;
  padding: $td-form-control-padding;

  // Text Input & Placeholder
  font-family: $td-form-element-font-family;
  font-size: $td-form-element-form-control-font-size;
  @if ($td-form-element-form-control-font-size-xs != $td-form-element-form-control-font-size) {
    @media (max-width: breakpoint-max(xs, $grid-breakpoints)) {
      font-size: $td-form-element-form-control-font-size-xs;
    }
  }

  &:-moz-placeholder {
    font-family: $td-form-element-font-family-2;
    color: $td-form-element-form-input-placeholder-color;
  }
  &::-moz-placeholder {
    font-family: $td-form-element-font-family-2;
    color: $td-form-element-form-input-placeholder-color;
  }
  &:-ms-input-placeholder {
    font-family: $td-form-element-font-family-2;
    color: $td-form-element-form-input-placeholder-color;
  }
  &::-ms-input-placeholder{
    font-family: $td-form-element-font-family-2;
    color: $td-form-element-form-input-placeholder-color;
  }
  &::-webkit-input-placeholder {
    font-family: $td-form-element-font-family-2;
    color: $td-form-element-form-input-placeholder-color;
  }


  // exception for radio and checkbox
  &[type="radio"], &[type="checkbox"]{
    height: auto; // cancel height setting, as radio and checkbox should not have this
  }

  &:focus, &:hover:not([disabled]) {
    border-bottom-width: $td-form-element-form-control-hover-focus-border-bottom-width;
    padding-bottom: $td-form-element-form-control-hover-focus-padding-bottom;
    border-color: $input-border-color;
    border-bottom-color: $input-focus-border-color;
    outline: none;
  }
}

// fieldset disabled - cancel hover effect
fieldset[disabled] .form-control:hover {
  border-bottom-width: 1px;
  padding-bottom: 3px;
  border-color: $td-form-element-form-control-disabled-border-color;
}

// Textarea
textarea.form-control {
  height: auto;
  resize: none;
  padding-top: 6px;
}
// ------ Base Forms & Textbox END --------//

// ------ Dropdown Select START -----//
.td-select {
  position: relative;

  select {
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;

    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -ms-user-select: none;
    appearance: none;

    padding-right: 34px;

    /* Remove IE native ui */
    &::-ms-expand {
      display: none;
    }

  }

  // removes the ouline in firefox for select on keyboard navigation
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $black;
  }

  // Remove blue background in IE11
  select:focus::-ms-value {
    background-color: white;
    color:#000;
  }

  // custom arrow
  &::after {
    content: "";
    pointer-events: none;
    position: absolute;

    font-size: 0.625em;
    line-height: 1;
    width: 0.5rem;
    height: 0.5rem;
    margin-top: -0.5em;
    top: 45%;
    right: 1.2em;
    color: $white;
    border: 3px solid $black;
    border-top-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 2px;
    border-right-width: 2px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: inline-block;
  }

  // custom arrow states
  &:hover::after {
    border-color: $td-colour-forms-primary-light;
  }
  &.select-disabled{
    // disabled custom arrow
    &::after {
      border-color: $td-form-element-form-control-disabled-border-color;
    }
    select[disabled]{
      font-family: $td-form-element-font-family-2;
    }
    &:hover::after {
      border-color: $td-form-element-form-control-disabled-border-color;
    }
  }
  &.is-invalid{
    &::after,
    &:hover::after{
      border-color: $td-colour-forms-alert;
    }

  }
}

// fieldset disabled
fieldset[disabled]{
  .td-select.select-disabled{
    select{
      font-family: $td-form-element-font-family-2;
    }
  }
}

// ------ Dropdown Select END -----//

// ------ Checkbox & Radio START ------//
.form-check {
  position: relative;
  display: block;
  padding-left: $td-radio-checkbox-size; // left padding to offset the width of the radio/checkbox
  margin-top: $td-radio-checkbox-top-margin; // top spacing of the first radio/checkbox element (determines spacing from legend)
  margin-bottom: $td-radio-checkbox-bottom-margin; // bottom spacing of the last radio/checkbox element

  label {
    display: inline-block;
    height: 100%;
    padding: $td-radio-checkbox-label-padding;
    margin: $td-radio-checkbox-label-margin;
    // pseudo element to allow vertical-align to work
    &::after {
      content:"";
      display: inline-block;
      height: 100%;
      vertical-align:middle;
    }
  }
  +.form-check {
    margin-top: $td-radio-checkbox-stacked-spacing;  // vertical spacing between stacked radio/checkbox elements
  }
}

.form-check-inline {
  display: inline-block;
  margin-top: $td-radio-checkbox-top-margin;
  margin-right: $td-radio-checkbox-inline-spacing; // horizontal spacing between inline radio/checkbox elements
  vertical-align: top;
}
.form-check-inline+.form-check-inline {
  margin-top: $td-radio-checkbox-top-margin;
  margin-left: 0; // cancel Bootstrap's rule to add margin on the left, margin is instead added on the right
}


.form-check input[type="radio"],
.form-check input[type="checkbox"] {
  // ----- Radio & checkbox positioning START -----//
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 0;
  margin: 0px;

  -webkit-appearance: none;
  -moz-appearance:none;
  background-color: transparent;
  border: solid $td-radio-checkbox-border-width $td-colour-forms-primary-light;
  width: $td-radio-checkbox-size;
  height: $td-radio-checkbox-size;
  outline: none;
  cursor: pointer;

  // IE
  &::-ms-check {
    border: solid $td-radio-checkbox-border-width $td-colour-forms-primary-light;
  }

  // FF
  @-moz-document url-prefix() {
    & {
      left: -1000px;
    }

    + label::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: $td-radio-checkbox-size;
      height: $td-radio-checkbox-size;
      border: solid $td-radio-checkbox-border-width $td-colour-forms-primary-light;
    }
  }
  // ----- Radio & checkbox positioning END -----//

  // ----- Radio & checkboxes states START -----//
  &:hover {
    background-color: $td-colour-gray-lighter;

    // IE
    &::-ms-check {
      background-color: $td-colour-gray-lighter;
    }

    // FF
    @-moz-document url-prefix() {
      + label::before {
        background-color: $td-colour-gray-lighter;
      }
    }
  }

  &:checked {
    // the image SVG data is now directly added in the css, no longer need to load the external .svg file - note the SVG data need to be url-encoded
    //background-image: url('../img/forms/checkmark.svg');
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2219%22%20height%3D%2216%22%20viewBox%3D%220%200%2019%2016%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20fill%3A%20%2300a22e%3B%0A%20%20%20%20%20%20%20%20stroke%3A%20%23fff%3B%0A%20%20%20%20%20%20%20%20stroke-linejoin%3A%20round%3B%0A%20%20%20%20%20%20%20%20stroke-width%3A%202px%3B%0A%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%0A%20%20%20%20%20%20%20%20filter%3A%20url(%23filter)%3B%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%3C%2Fstyle%3E%0A%20%20%20%20%3Cfilter%20id%3D%22filter%22%20x%3D%222666%22%20y%3D%225985%22%20width%3D%2219%22%20height%3D%2216%22%20filterUnits%3D%22userSpaceOnUse%22%3E%0A%20%20%20%20%20%20%3CfeFlood%20result%3D%22flood%22%20flood-color%3D%22%23fff%22%2F%3E%0A%20%20%20%20%20%20%3CfeComposite%20result%3D%22composite%22%20operator%3D%22in%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%20%20%3CfeBlend%20result%3D%22blend%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%3C%2Ffilter%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Cg%20transform%3D%22translate(-2666%20-5985)%22%20style%3D%22fill%3A%20%2300a22e%3B%20filter%3A%20url(%23filter)%22%3E%0A%20%20%20%20%3Cpath%20id%3D%22checkmark%22%20class%3D%22cls-1%22%20d%3D%22M2673.31%2C5997.34l-0.78-.81%2C7.76-8.09%2C0.78%2C0.81Zm-3.85-4.11%2C0.82-.78%2C3.81%2C4.02-0.82.78Z%22%20style%3D%22stroke%3A%20inherit%3B%20filter%3A%20none%3B%20fill%3A%20inherit%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%20%20%3Cuse%20transform%3D%22translate(-2666%20-5985)%22%20xlink%3Ahref%3D%22%23checkmark%22%20style%3D%22stroke%3A%20%23fff%3B%20filter%3A%20none%3B%20fill%3A%20none%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-size: 18px 18px;
    background-color: $td-colour-forms-primary-light;
    background-position: center;

    // IE
    &::-ms-check {
      // see above note on loading SVG
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2219%22%20height%3D%2216%22%20viewBox%3D%220%200%2019%2016%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20fill%3A%20%2300a22e%3B%0A%20%20%20%20%20%20%20%20stroke%3A%20%23fff%3B%0A%20%20%20%20%20%20%20%20stroke-linejoin%3A%20round%3B%0A%20%20%20%20%20%20%20%20stroke-width%3A%202px%3B%0A%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%0A%20%20%20%20%20%20%20%20filter%3A%20url(%23filter)%3B%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%3C%2Fstyle%3E%0A%20%20%20%20%3Cfilter%20id%3D%22filter%22%20x%3D%222666%22%20y%3D%225985%22%20width%3D%2219%22%20height%3D%2216%22%20filterUnits%3D%22userSpaceOnUse%22%3E%0A%20%20%20%20%20%20%3CfeFlood%20result%3D%22flood%22%20flood-color%3D%22%23fff%22%2F%3E%0A%20%20%20%20%20%20%3CfeComposite%20result%3D%22composite%22%20operator%3D%22in%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%20%20%3CfeBlend%20result%3D%22blend%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%3C%2Ffilter%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Cg%20transform%3D%22translate(-2666%20-5985)%22%20style%3D%22fill%3A%20%2300a22e%3B%20filter%3A%20url(%23filter)%22%3E%0A%20%20%20%20%3Cpath%20id%3D%22checkmark%22%20class%3D%22cls-1%22%20d%3D%22M2673.31%2C5997.34l-0.78-.81%2C7.76-8.09%2C0.78%2C0.81Zm-3.85-4.11%2C0.82-.78%2C3.81%2C4.02-0.82.78Z%22%20style%3D%22stroke%3A%20inherit%3B%20filter%3A%20none%3B%20fill%3A%20inherit%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%20%20%3Cuse%20transform%3D%22translate(-2666%20-5985)%22%20xlink%3Ahref%3D%22%23checkmark%22%20style%3D%22stroke%3A%20%23fff%3B%20filter%3A%20none%3B%20fill%3A%20none%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      background-size: 18px 16px;
      background-position: center;
      background-color: $td-colour-forms-primary-light;
      color: transparent; // hide IE arrow
    }
    // FF
    @-moz-document url-prefix() {
      + label::before {
        // see above note on loading SVG
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2219%22%20height%3D%2216%22%20viewBox%3D%220%200%2019%2016%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20fill%3A%20%2300a22e%3B%0A%20%20%20%20%20%20%20%20stroke%3A%20%23fff%3B%0A%20%20%20%20%20%20%20%20stroke-linejoin%3A%20round%3B%0A%20%20%20%20%20%20%20%20stroke-width%3A%202px%3B%0A%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%0A%20%20%20%20%20%20%20%20filter%3A%20url(%23filter)%3B%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%3C%2Fstyle%3E%0A%20%20%20%20%3Cfilter%20id%3D%22filter%22%20x%3D%222666%22%20y%3D%225985%22%20width%3D%2219%22%20height%3D%2216%22%20filterUnits%3D%22userSpaceOnUse%22%3E%0A%20%20%20%20%20%20%3CfeFlood%20result%3D%22flood%22%20flood-color%3D%22%23fff%22%2F%3E%0A%20%20%20%20%20%20%3CfeComposite%20result%3D%22composite%22%20operator%3D%22in%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%20%20%3CfeBlend%20result%3D%22blend%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%3C%2Ffilter%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Cg%20transform%3D%22translate(-2666%20-5985)%22%20style%3D%22fill%3A%20%2300a22e%3B%20filter%3A%20url(%23filter)%22%3E%0A%20%20%20%20%3Cpath%20id%3D%22checkmark%22%20class%3D%22cls-1%22%20d%3D%22M2673.31%2C5997.34l-0.78-.81%2C7.76-8.09%2C0.78%2C0.81Zm-3.85-4.11%2C0.82-.78%2C3.81%2C4.02-0.82.78Z%22%20style%3D%22stroke%3A%20inherit%3B%20filter%3A%20none%3B%20fill%3A%20inherit%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%20%20%3Cuse%20transform%3D%22translate(-2666%20-5985)%22%20xlink%3Ahref%3D%22%23checkmark%22%20style%3D%22stroke%3A%20%23fff%3B%20filter%3A%20none%3B%20fill%3A%20none%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        background-size: auto;
        background-position: right 80% top 50%;
        background-color: $td-colour-forms-primary-light;
      }
    }
  }

  &:focus {
    outline: 1px dotted $td-colour-primary-dark-2;
    outline-offset: 1px;

    @-moz-document url-prefix() {
      + label::before {
        outline: 1px dotted $td-colour-primary-dark-2;
        outline-offset: 1px;
      }
    }
  }

  &:disabled {
    border-color: $td-form-element-form-check-disabled-border-color;
    background-color: $td-form-element-form-check-disabled-background-color;
    //cursor: not-allowed;    // Bootstrap 4 does not use this styling any longer
    opacity: 1; // fix for iOS Safari, otherwise opacity will be reduced

    // IE
    &::-ms-check {
      border-color: $input-border-color;
    }

    // FF
    @-moz-document url-prefix() {
      + label::before {
        border-color: $input-border-color;
        background-color: $td-colour-gray-lighter;
        cursor: not-allowed;
      }
    }
  }
  // ----- Radio & checkboxes states END -----//
}

// fieldset disabled
fieldset[disabled] .form-check input[type="radio"],
fieldset[disabled] .form-check input[type="checkbox"] {
  // IE - cancel border active colour
  &::-ms-check {
    border-color: $input-border-color;
  }
}

// ------ Checkbox & Radio END ------//

// ------ Checkbox only styling START ------//
.form-check input[type="checkbox"]{
  border-radius: 0; // ensure borders are not rounded - fix for iOS Safari (which sets a border radius on checkboxes)
}
// ------ Checkbox only styling END ------//

// ------ Radio only styling START ------//
.form-check input[type="radio"] {
  border-radius: 50%;

  // IE
  &::-ms-check {
    border-radius: 50%;
  }
  // FF
  @-moz-document url-prefix() {
    + label::before {
      border-radius: 50%;
    }
  }

  // selected state
  &:checked {
    border-width: 5px;
    background: white;

    // IE
    &::-ms-check {
      border-width: 5px;
      background: white;
    }
    // FF
    @-moz-document url-prefix() {
      + label::before {
        border-width: 5px;
        background: white;
      }
    }
  }
}
// ------ Radio only styling END ------//


// ------ Multi/Single Select Buttons START ------//
.td-multi-single-select-buttons {
  margin-top:5px;

  legend {
    margin-bottom: 10px;
  }

  .td-multi-single-select-button-wrapper {
    display: inline-block;
    position: relative;
    float: left;
    padding: 0 20px 15px 0;
    margin: 0;

    @include media-breakpoint-only(xs) {
      min-width: 100%;   // set min-width to full width of screen on mobile, this overrides any fixed width that's set inline
      padding-right: 0;
    }
  }

  // hide the actual radio/checkbox
  input[type="radio"],
  input[type="checkbox"] {
    position: absolute; // absolutely position to take the element out of the flow
    top: 0;
    left: 0;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance:none;

    // IE
    &::-ms-check {
      border: none;
      background: transparent;
    }
  }

  // select button
  label {
    font-family: $td-font-family-sans-serif-bold;

    display: -webkit-flex;  // needed for older versions of Safari
    display: flex;

    -webkit-box-align: center;  // needed for older versions of Safari
    -webkit-align-items: center;  // needed for older versions of Safari
    align-items: center;

    -webkit-justify-content: center;  // needed for older versions of Safari
    justify-content: center;

    position: relative;
    float: left;
    cursor: pointer;
    background: #fff;
    text-align: center;
    border: $td-multi-single-select-button-border-width solid $td-colour-primary-light-2;
    border-radius: $td-multi-single-select-button-border-radius;
    padding: 4px 56px 6px 56px;
    margin: 0;

    -webkit-user-select: none;   // needed for older versions of Safari
    user-select: none;

    width: auto;
    min-width: 100%;
    height: 100%;

    // focus outline START
    &::before {
      border-radius: $btn-border-radius;
      border: 2px solid $td-colour-primary-light-1;
      bottom: -2px;
      content: '';
      display: block;
      left: -2px;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: -2px;
      transition: all 150ms ease-in-out;
    }
    //focus outline END
  }

  // input states
  input[type="radio"]:hover,
  input[type="checkbox"]:hover,
  input[type="radio"]:focus,
  input[type="checkbox"]:focus {
    + label {
      background: $td-multi-single-select-button-hover-bg;
    }
  }
  input[type="radio"]:focus,
  input[type="checkbox"]:focus {
    + label::before {
      bottom: 3px;
      left: 3px;
      opacity: 0.75;
      right: 3px;
      top: 3px;
    }
  }
  input[type="radio"]:checked,
  input[type="checkbox"]:checked {
    &:focus + label::before {
        //opacity: 0;
        border-color: $white;
    }

    + label {
      color: $white;
      background: $td-multi-single-select-button-checked-bg;
    }
  }

  input[type="radio"]:disabled,
  input[type="checkbox"]:disabled {
    + label {
      color: $td-multi-single-select-button-disabled-color;
      background-color: $td-multi-single-select-button-disabled-bg;
      border-color: $td-multi-single-select-button-disabled-border;

      &:hover {
        cursor: default;
      }
    }
  }
}

// Variation: Segmented Single Select Button
.td-multi-single-select-buttons.td-multi-single-select-buttons-segmented {
  .td-multi-single-select-button-wrapper {
    height: 42px;
    width: 50%;       // default 50% width when there are 2 buttons, for 3+ buttons the percentage width must be customized (i.e. 3 buttons = 33.3% width)
    min-width: 50%;   // default 50% width when there are 2 buttons, for 3+ buttons the percentage width must be customized (i.e. 3 buttons = 33.3% width)
    padding: 0;

    label {
      padding-left: 30px;  // reduce horizontal padding as the total width is dynamic based on the container size
      padding-right: 30px;
      border-radius: 0;
      border-right: none;
    }
    &:first-child label {
      border-top-left-radius: $td-multi-single-select-button-border-radius;
      border-bottom-left-radius: $td-multi-single-select-button-border-radius;
    }
    &:last-child label {
      border-top-right-radius: $td-multi-single-select-button-border-radius;
      border-bottom-right-radius: $td-multi-single-select-button-border-radius;
      border-right: $td-multi-single-select-button-border-width solid $td-colour-primary-light-2;
    }
  }
}
// ------ Multi/Single Select Buttons END ------//

// custom legend style (same as previous Emerald .td-sim-legend class)
legend {
  font-size: $td-body-font-size;
  padding: 0px;
  display:block;
  margin-bottom:0px;
  border: 0;
  color: $td-colour-almost-black;
}

// inline form
@include media-breakpoint-up(sm) {
  .form-inline {
    label+.form-control,
    label+.input-group,
    label+.td-select {
      margin-top: 0;  // remove top margin for proper alignment
    }

    .form-check {
      vertical-align: baseline;
      margin-right: $td-radio-checkbox-inline-spacing;
      margin-left: 15px;
      padding-left: $td-radio-checkbox-size;

      label {
        padding: $td-radio-checkbox-label-padding;
      }
    }
    // fix to allow Bootstrap's .form-inline to work with Emerald
    .form-check input[type="radio"],
    .form-check input[type="checkbox"] {
      position: absolute;
    }
  }
}

// ------ Validation States START ------//

// Base Forms & Textbox
.form-control.is-invalid, .form-control:invalid{
  color: $td-form-element-form-control-is-invalid-color;
  border-bottom-width: $td-form-element-form-control-is-invalid-border-bottom-width;
  padding-bottom: $td-form-element-form-control-is-invalid-hover-focus-padding-bottom;
  border-color: $input-border-color;  // cancel Bootstrap's setting of the entire border colour
  border-bottom-color: $td-colour-forms-alert;
  background-image: none; // remove Bootstrap's error icon

  &:hover, &:focus {
    padding-bottom: 2px;
    border-bottom-color: $td-colour-forms-alert;
  }

  // cancel Bootstrap's focus effects
  &:focus {
    border-color: $input-border-color;  // cancel Bootstrap's setting of the entire border colour
    border-bottom-color: $td-colour-forms-alert;
    box-shadow: none;
    outline-style:dotted;
    outline-color: $td-colour-primary-light-2;
    outline-width:1px;
  }
}

// Dropdown Select
//.has-error .td-select {
//  &::after {
//    border-color: $td-colour-forms-alert;
//  }
//}

// Radio & Checkbox
.form-check input[type="radio"]:not(:checked).is-invalid,
.form-check input[type="checkbox"]:not(:checked).is-invalid,
.form-check input[type="radio"]:not(:checked):invalid,
.form-check input[type="checkbox"]:not(:checked):invalid {
  border-color: $td-colour-forms-alert;
  background-color: $td-colour-messaging-error;

  // IE
  &::-ms-check {
    border-color: $td-colour-forms-alert;
    background-color: $td-colour-messaging-error;
  }

  // FF
  @-moz-document url-prefix() {
    + label::before {
      border-color: $td-colour-forms-alert;
      background-color: $td-colour-messaging-error;
    }
  }
}
.form-check input[type="radio"].is-invalid,
.form-check input[type="checkbox"].is-invalid,
.form-check input[type="radio"]:invalid,
.form-check input[type="checkbox"]:invalid {
  & ~ .form-check-label {
    color: $td-colour-font-dark; // cancel Bootstrap's colour on labels
  }
}


// Error message
.td-error, .invalid-feedback {
  color: $td-colour-forms-alert;
  font-size: 0.75rem;
  padding: 4px 0;
  margin-top: 0;

  .td-error-symbol {
    font-family: $td-font-family-sans-serif-bold;

    &::before {
      content: "! ";  // content can be changed to use a font icon instead, see _td_icons_classes.scss
    }
  }
}
.td-error{
  font-size: $td-form-element-td-error-font-size;
  font-family: $td-form-element-font-family;
}

// ------ Validation States END ------//


// 'not-allowed' cursor for 'Disable Fields' & 'Disable Fieldsets'
.form-control[disabled],
fieldset[disabled] .form-control,
.form-check input[type="radio"]:disabled,
.form-check input[type="checkbox"]:disabled{
  cursor: not-allowed;
}

// style for disabled text
.td-text-disabled{
  color: $td-form-element-disabled-text;
}

