.td-flag{
  position: relative;
  display: inline-block;
  padding: 4px 15px;
  line-height: 1.5;
  font-family: $td-font-family-sans-serif-bold;
  font-size: 0.6875rem;

  // special offer
  &.td-flag-special-offer{
    color: $td-colour-font-4;
    background-color: $td-colour-bg-3;
    border-color: $td-colour-bg-3;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      border: 12px solid transparent;
      border-top-color: inherit;
      border-bottom-color: inherit;
      right: -12px;
    }
    &.td-flag-right{
      &:after{
        left: -12px;
        right: auto;
      }
    }
  }

  // recently viewed
  &.td-flag-recently-viewed{
    color: $td-colour-font-1;
    background-color: $td-colour-bg-1;
    align-content: center;
    border-radius: 0 0 5px 0;
    &.td-flag-right{
      border-radius: 0 0 0 5px;
    }
  }
}
