
.btn {
  position: relative;
  color: $white;

  // button focus outline START
  &::before {
    border-radius: $btn-border-radius;
    border: 2px solid $white;
    bottom: -2px;
    content: '';
    display: block;
    left: -2px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: -2px;
    top: -2px;
    transition: all 150ms ease-in-out;
  }

  &.focus,
  &:focus {
    &::before {
      bottom: 3px;
      left: 3px;
      opacity: 0.75;
      right: 3px;
      top: 3px;
    }
  }

  &:hover {
    &::before {
      opacity: 0; // prevents focus outline from showing while the button is clicked and the mouse is hovering on top
    }
  }
  // button focus outline END

  // button with icon
  span.td-icon {
    display: inline-block !important;
    text-align: left;
    vertical-align: middle;
    font-size: 1.375rem;

    margin-top: -4px; // adjust icon positioning
    color: inherit;   // icon colour will be same as the button text colour

    @include media-breakpoint-only(sm) {
      font-size: 1.25rem;
    }
  }
  // Apply right padding to :before, this ensures that an empty <span class="td-icon"> won't have the padding
  span.td-icon:before {
    line-height: 1;
    display: inline;
    padding-right: 15px;

    @include media-breakpoint-only(sm) {
      padding-right: 7px;
    }
  }
}

// colour classes
.td-btn-CTA, .btn-primary {
  @include button-variant($td-colour-btn-CTA, $td-colour-btn-CTA, $td-colour-btn-CTA-hover, $td-colour-btn-CTA-hover, $td-colour-btn-CTA-active, $td-colour-btn-CTA-active);

  &:hover{
    color: color-yiq($td-colour-btn-CTA);
  }
  &.hover {
    background-color: $td-colour-btn-CTA-hover;
    border-color: $td-colour-btn-CTA-hover;
  }
}

.td-btn-primary-light, .btn-secondary {
  @include button-variant($td-colour-btn-primary-light, $td-colour-btn-primary-light, $td-colour-btn-primary-light-hover, $td-colour-btn-primary-light-hover, $td-colour-btn-primary-light-active, $td-colour-btn-primary-light-active);
  &.hover {
    background-color: $td-colour-btn-primary-light-hover;
    border-color: $td-colour-btn-primary-light-hover;
  }
}

.td-btn-primary-dark {
  @include button-variant($td-colour-btn-primary-dark, $td-colour-btn-primary-dark, $td-colour-btn-primary-dark-hover, $td-colour-btn-primary-dark-hover, $td-colour-btn-primary-dark-active, $td-colour-btn-primary-dark-active);
  &:hover,
  &.hover {
    background-color: $td-colour-btn-primary-dark-hover;
    border-color: $td-colour-btn-primary-dark-hover;
  }
}

.td-btn-secondary-clear {
  @include button-variant(transparent, $td-colour-btn-secondary-clear-border, $td-colour-btn-secondary-clear-hover, $td-colour-btn-secondary-clear, $td-colour-btn-secondary-clear-active, $td-colour-btn-secondary-clear);

  color: $td-colour-btn-secondary-clear;
  border-width: $td-btn-secondary-clear-border-width;
  padding: $input-btn-padding-x-thick-border $input-btn-padding-y-thick-border;

  &.hover,
  &:hover {
    color: $td-colour-btn-secondary-clear;
    background-color: $td-colour-btn-secondary-clear-hover;
  }

  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
    color: $td-colour-btn-secondary-clear;
  }

  &::before {
    border-color: $td-colour-btn-primary-light;  // focus outline
  }
}

.td-btn-secondary-clear-white {
  @include button-variant(transparent, $td-colour-btn-secondary-clear-white, $td-colour-btn-secondary-clear-white-hover, $td-colour-btn-secondary-clear-white, $td-colour-btn-secondary-clear-white-active, $td-colour-btn-secondary-clear-white);

  color: $td-colour-btn-secondary-clear-white;
  border-width: $td-btn-secondary-clear-white-border-width;
  padding: $input-btn-padding-x-thick-border $input-btn-padding-y-thick-border;

  &.hover,
  &:hover {
    color: $td-colour-btn-secondary-clear-white;
    background-color: $td-colour-btn-secondary-clear-white-hover;
  }
}

// disabled
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  // all disabled buttons have the same colour, no matter the original button style
  color: $td-colour-btn-disabled-color;
  background-color: $td-colour-btn-disabled-bg;
  border-color: $td-colour-btn-disabled-border;
  border-width: $td-btn-disabled-border-width;
  padding: $input-btn-padding-x-thick-border $input-btn-padding-y-thick-border;

  &:hover,
  &:focus,
  &.focus {
    // all disabled buttons have the same colour, no matter the original button style
    color: $td-colour-btn-disabled-color;
    background-color: $td-colour-btn-disabled-bg;
    border-color: $td-colour-btn-disabled-border;
  }
}
