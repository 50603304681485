//TODO remove unused classes

$icomoon-font-path: $td-icon-font-path !default;  // map IcoMoon's font path variable to TD's

@import "td_icons_classes"; // icon class definitions from IcoMoon

// include icon classes here as part of the compiled css, but if the option to use an external icon css file is 'true' then don't include
@if $td-use-external-icon-css-file == false { @include td-icon-classes(); }

.td-icon-wrapper{
  display: inline-block;
  text-align: center;
  position: relative;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  &.td-interactive-icon{
    text-decoration: none;
    outline: none;
    &.clicked{
      background-color: $td-colour-primary-light-1;
      border-color: $td-colour-primary-light-1;
      .td-icon{
        color: $white;
      }
    }
    &.icon-small {
      border-width: $td-icon-wrapper-small-border-width;
    }
    &.icon-regular {
      border-width: $td-icon-wrapper-regular-border-width;
    }
    &.icon-medium {
      border-width: $td-icon-wrapper-medium-border-width;
    }
    &.icon-large {
      border-width: $td-icon-wrapper-large-border-width;
    }

    border: solid 2px $gray-500;
    .td-icon{
      color: $td-colour-primary-light-1;
    }
    &:hover, &:focus{
      border-color: $td-colour-primary-light-1;
    }
    &:active{
      border-color: $td-colour-primary-light-1;
      background-color: $td-colour-primary-light-1;
      outline: none;
      .td-icon{
        color:$white;
      }
    }
    &.td-background-darkgreen{
      border-color: $td-colour-primary-light-1;
      .td-icon{
        color:$white
      }
      &:active{
        background-color: $td-colour-primary-light-1;
      }
      &:hover, &:focus {
        border-color:  $td-colour-primary-light-1;
        background-color: $td-colour-primary-light-1;
      }
    }
    &.td-background-brightgreen{
      background-color: $td-colour-primary-light-1;
      border: none;
    }
    &.td-background-orange{
      background-color: $td-colour-btn-CTA;
      border: none;
    }
  }
  .td-icon{
    color: $td-colour-primary-light-1;
    line-height: 1.42857;
  }

  &.icon-small{
    height: 30px;
    width: 30px;
    border-width: 1px;
    .td-icon{
      font-size: 21px;
    }
  }
  &.icon-regular{
    height: 50px;
    width: 50px;
    border-width: 2px;
    .td-icon{
      font-size: 35px;
    }
  }
  &.icon-medium{
    height: 75px;
    width: 75px;
    border-width: 2px;
    .td-icon{
      font-size: 50px;
    }
  }
  &.icon-large{
    height: 100px;
    width: 100px;
    border-width: 2px;
    .td-icon{
      font-size: 70px;
    }
  }
  &.icon-xlarge{
    height: 150px;
    width: 150px;
    border-width: 2px;
    .td-icon{
      font-size: 100px;
    }
  }
}

.td-icon-wrapper.td-background-darkgreen {
  border-color: $td-colour-primary-light-1;
  .td-icon{
    color:$white;
  }
  text-decoration: none;
}
.td-icon-wrapper.td-background-darkgreen.td-icon-border-dark{
  border-color: $td-colour-primary-dark-1;
}

// note: any changes to the .td-icon class should be duplicated in the %td-icon placeholder selector in _td_icons_placeholders.scss
.td-icon {
  font-family: #{$icomoon-font-family};
  &.icon-small{
    font-size: 20px;
    line-height: 1;
  }
  &.icon-regular{
    font-size: 35px;
    line-height: 1;
  }
  &.icon-medium{
    font-size: 70px;
    line-height: 1;
  }
  &.icon-large{
    font-size: 140px;
    line-height: 1;
  }
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  position:relative;
  text-decoration: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  // styles to fix the width of the icons
  &:before {
    width: 1em; // set width equal to font size
    text-align: center; // center the icon so there's equal padding on both sides
    display: inline-block;  // allow sizing to be changed
  }

  // sets the icon width to not be fixed (will take the width from the illustrator glyph)
  &.td-icon-variable-width:before {
    width: auto;
  }
}



.td-icon-colour-primary {
  color: $td-colour-primary-dark-1;
}

@media aural {
  .td-icon {
    speak: none;
  }
}

a .td-icon{
  text-decoration: none;
}
a.td-icon-link{
  text-decoration: none;
}


.td-icon-wrapper:hover .td-icon:not(:hover) {
  color: $td-colour-primary-light-1;
}
.td-icon-wrapper:hover.td-background-darkgreen .td-icon:not(:hover) {
  border-color: $td-colour-primary-light-1;
  color: $white;
}
.td-icon-wrapper:active.td-background-darkgreen .td-icon:not(:hover) {
  background-color: $td-colour-primary-light-1;
}

// td-icon-sort
// This is a CSS-only icon that doesn't use a font glyph
.td-icon.td-icon-sort {
  // Using a relative font size makes scaling easy
  $td-icon-sort-size: 0.25em;

  // The triangle icons are pseudo-elements, using a borders to simulate a triangle shape.
  // the combination of the border size and horizontal margin adds up to 1em to take up the same spacing as a regular icon
  &::before,
  &::after {
    border-left: $td-icon-sort-size solid transparent;
    border-right: $td-icon-sort-size solid transparent;
    content: '';
    display: inline-block;
    width: auto;
    margin-left: $td-icon-sort-size;
    margin-right: $td-icon-sort-size;
    transition: all 150ms ease-in-out;
  }

  // 'up' arrow, is like a regular icon glyph but positioned with a bottom margin to push it up into position
  &::before {
    border-bottom: $td-icon-sort-size solid currentColor;
    margin-bottom: $td-icon-sort-size * 2;
  }

  // 'down' arrow, is like an 'extra' glyph and is positioned absolutely (we don't want it to take up any actual spacing since the 'up' arrow is already doing this)
  &::after {
    border-top: $td-icon-sort-size solid currentColor;
    position: absolute;
    bottom: $td-icon-sort-size / 2 + 0.06em;
    left: 0;
  }

  // Change the apperance based on sort direction helper classes
  &.td-icon-sort-asc {
    &::after {
      opacity: 0.35;
    }
  }

  &.td-icon-sort-desc {
    &::before {
      opacity: 0.35;
    }
  }

  // This needs to be applied to .td-icon, otherwise it has no effect
  &.td-icon-variable-width {
    &::before,
    &::after {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
