@charset "UTF-8";
/*!
 * TD Emerald Standards Bootstrap 4 - v4.5.15
 * Core framework & elements
 */
/***
* Custom variables & overrides to Bootstrap variables
* Note: new custom variables that are not from Bootstrap are prefixed with "td-"
 */
/* Fonts */
/* 48px */
/* 35px */
/* 23px */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #616161;
  --gray-dark: #343a40;
  --primary: #1a5336;
  --secondary: #ff9500;
  --success: #f0f4f0;
  --info: #f0f4f0;
  --warning: #fdf2df;
  --danger: #f7e8e9;
  --light: #f9f9f9;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 768px;
  --breakpoint-md: 1024px;
  --breakpoint-lg: 1200px;
  --font-family-sans-serif: "TD Graphik SemiLight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "TD Graphik SemiLight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #1c1c1c;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #038203;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #038203;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 30px 15px;
  padding-bottom: 30px 15px;
  color: #616161;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.625rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: inherit;
}

h1, .h1 {
  font-size: 1.625rem;
}

h2, .h2 {
  font-size: 1.375rem;
}

h3, .h3 {
  font-size: 1.125rem;
}

h4, .h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 0.83rem;
}

h6, .h6 {
  font-size: 0.8125rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: inherit;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: inherit;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: inherit;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: inherit;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #616161;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #616161;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #1c1c1c;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #1c1c1c;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container, .container-sm {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .container, .container-sm, .container-md {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1200px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1024px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #1c1c1c;
}

.table th,
.table td {
  padding: 30px 15px;
  vertical-align: top;
  border-top: 1px solid #cccccc;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #cccccc;
}

.table tbody + tbody {
  border-top: 2px solid #cccccc;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #cccccc;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #cccccc;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #1c1c1c;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bfcfc7;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #88a696;
}

.table-hover .table-primary:hover {
  background-color: #b0c4ba;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b0c4ba;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ffe1b8;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #ffc87a;
}

.table-hover .table-secondary:hover {
  background-color: #ffd69f;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #ffd69f;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #fbfcfb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #f7f9f7;
}

.table-hover .table-success:hover {
  background-color: #ecf1ec;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #ecf1ec;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #fbfcfb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #f7f9f7;
}

.table-hover .table-info:hover {
  background-color: #ecf1ec;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #ecf1ec;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fefbf6;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fef8ee;
}

.table-hover .table-warning:hover {
  background-color: #fbf1df;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbf1df;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fdf9f9;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fbf3f4;
}

.table-hover .table-danger:hover {
  background-color: #f7e6e6;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f7e6e6;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfc;
}

.table-hover .table-light:hover {
  background-color: #f0f0f0;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f0f0f0;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #f9f9f9;
  border-color: #cccccc;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 767.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1023.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.375em + 1rem + 2px);
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #1c1c1c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #8c8c8c;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #1c1c1c;
  background-color: #fff;
  border-color: #008a00;
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder {
  color: #767676;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #cccccc;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1c1c1c;
}

select.form-control:focus::-ms-value {
  color: #1c1c1c;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.375;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.375;
  color: #1c1c1c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #616161;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f0f4f0;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.375;
  color: #1c1c1c;
  background-color: rgba(240, 244, 240, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #f0f4f0;
  padding-right: calc(1.375em + 1rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23f0f4f0' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.34375em + 0.25rem) center;
  background-size: calc(0.6875em + 0.5rem) calc(0.6875em + 0.5rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #f0f4f0;
  box-shadow: 0 0 0 0.2rem rgba(240, 244, 240, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 2rem !important;
  background-position: right 1rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.375em + 1rem);
  background-position: top calc(0.34375em + 0.25rem) right calc(0.34375em + 0.25rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #f0f4f0;
  padding-right: calc(0.75em + 2.25rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.5rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23f0f4f0' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.5rem/calc(0.6875em + 0.5rem) calc(0.6875em + 0.5rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #f0f4f0;
  box-shadow: 0 0 0 0.2rem rgba(240, 244, 240, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #f0f4f0;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #f0f4f0;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #f0f4f0;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: white;
  background-color: white;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(240, 244, 240, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f0f4f0;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #f0f4f0;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #f0f4f0;
  box-shadow: 0 0 0 0.2rem rgba(240, 244, 240, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f7e8e9;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.375;
  color: #1c1c1c;
  background-color: rgba(247, 232, 233, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f7e8e9;
  padding-right: calc(1.375em + 1rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f7e8e9' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f7e8e9' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.34375em + 0.25rem) center;
  background-size: calc(0.6875em + 0.5rem) calc(0.6875em + 0.5rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f7e8e9;
  box-shadow: 0 0 0 0.2rem rgba(247, 232, 233, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 2rem !important;
  background-position: right 1rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.375em + 1rem);
  background-position: top calc(0.34375em + 0.25rem) right calc(0.34375em + 0.25rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f7e8e9;
  padding-right: calc(0.75em + 2.25rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.5rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f7e8e9' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f7e8e9' stroke='none'/%3e%3c/svg%3e") center right 1.5rem/calc(0.6875em + 0.5rem) calc(0.6875em + 0.5rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #f7e8e9;
  box-shadow: 0 0 0 0.2rem rgba(247, 232, 233, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f7e8e9;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f7e8e9;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #f7e8e9;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: white;
  background-color: white;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(247, 232, 233, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f7e8e9;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f7e8e9;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f7e8e9;
  box-shadow: 0 0 0 0.2rem rgba(247, 232, 233, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 768px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  color: #1c1c1c;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 0rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #1c1c1c;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #1a5336;
  border-color: #1a5336;
}

.btn-primary:hover {
  color: #fff;
  background-color: #113623;
  border-color: #0e2c1d;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #113623;
  border-color: #0e2c1d;
  box-shadow: 0 0 0 0 rgba(60, 109, 84, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1a5336;
  border-color: #1a5336;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0e2c1d;
  border-color: #0b2216;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(60, 109, 84, 0.5);
}

.btn-secondary {
  color: #1c1c1c;
  background-color: #ff9500;
  border-color: #ff9500;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #d97f00;
  border-color: #cc7700;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #d97f00;
  border-color: #cc7700;
  box-shadow: 0 0 0 0 rgba(221, 131, 4, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #1c1c1c;
  background-color: #ff9500;
  border-color: #ff9500;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #cc7700;
  border-color: #bf7000;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(221, 131, 4, 0.5);
}

.btn-success {
  color: #1c1c1c;
  background-color: #f0f4f0;
  border-color: #f0f4f0;
}

.btn-success:hover {
  color: #1c1c1c;
  background-color: #dae4da;
  border-color: #d3ded3;
}

.btn-success:focus, .btn-success.focus {
  color: #1c1c1c;
  background-color: #dae4da;
  border-color: #d3ded3;
  box-shadow: 0 0 0 0 rgba(208, 212, 208, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #1c1c1c;
  background-color: #f0f4f0;
  border-color: #f0f4f0;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #1c1c1c;
  background-color: #d3ded3;
  border-color: #cbd9cb;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(208, 212, 208, 0.5);
}

.btn-info {
  color: #1c1c1c;
  background-color: #f0f4f0;
  border-color: #f0f4f0;
}

.btn-info:hover {
  color: #1c1c1c;
  background-color: #dae4da;
  border-color: #d3ded3;
}

.btn-info:focus, .btn-info.focus {
  color: #1c1c1c;
  background-color: #dae4da;
  border-color: #d3ded3;
  box-shadow: 0 0 0 0 rgba(208, 212, 208, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #1c1c1c;
  background-color: #f0f4f0;
  border-color: #f0f4f0;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #1c1c1c;
  background-color: #d3ded3;
  border-color: #cbd9cb;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(208, 212, 208, 0.5);
}

.btn-warning {
  color: #1c1c1c;
  background-color: #fdf2df;
  border-color: #fdf2df;
}

.btn-warning:hover {
  color: #1c1c1c;
  background-color: #fbe3bb;
  border-color: #fadfaf;
}

.btn-warning:focus, .btn-warning.focus {
  color: #1c1c1c;
  background-color: #fbe3bb;
  border-color: #fadfaf;
  box-shadow: 0 0 0 0 rgba(219, 210, 194, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #1c1c1c;
  background-color: #fdf2df;
  border-color: #fdf2df;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #1c1c1c;
  background-color: #fadfaf;
  border-color: #f9daa3;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(219, 210, 194, 0.5);
}

.btn-danger {
  color: #1c1c1c;
  background-color: #f7e8e9;
  border-color: #f7e8e9;
}

.btn-danger:hover {
  color: #1c1c1c;
  background-color: #edccce;
  border-color: #eac2c5;
}

.btn-danger:focus, .btn-danger.focus {
  color: #1c1c1c;
  background-color: #edccce;
  border-color: #eac2c5;
  box-shadow: 0 0 0 0 rgba(214, 201, 202, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #1c1c1c;
  background-color: #f7e8e9;
  border-color: #f7e8e9;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #1c1c1c;
  background-color: #eac2c5;
  border-color: #e7b9bc;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(214, 201, 202, 0.5);
}

.btn-light {
  color: #1c1c1c;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}

.btn-light:hover {
  color: #1c1c1c;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}

.btn-light:focus, .btn-light.focus {
  color: #1c1c1c;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
  box-shadow: 0 0 0 0 rgba(216, 216, 216, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #1c1c1c;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #1c1c1c;
  background-color: #e0e0e0;
  border-color: #d9d9d9;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(216, 216, 216, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0 rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #1a5336;
  border-color: #1a5336;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1a5336;
  border-color: #1a5336;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(26, 83, 54, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1a5336;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1a5336;
  border-color: #1a5336;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(26, 83, 54, 0.5);
}

.btn-outline-secondary {
  color: #ff9500;
  border-color: #ff9500;
}

.btn-outline-secondary:hover {
  color: #1c1c1c;
  background-color: #ff9500;
  border-color: #ff9500;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0 rgba(255, 149, 0, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ff9500;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #1c1c1c;
  background-color: #ff9500;
  border-color: #ff9500;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 149, 0, 0.5);
}

.btn-outline-success {
  color: #f0f4f0;
  border-color: #f0f4f0;
}

.btn-outline-success:hover {
  color: #1c1c1c;
  background-color: #f0f4f0;
  border-color: #f0f4f0;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(240, 244, 240, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #f0f4f0;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #1c1c1c;
  background-color: #f0f4f0;
  border-color: #f0f4f0;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(240, 244, 240, 0.5);
}

.btn-outline-info {
  color: #f0f4f0;
  border-color: #f0f4f0;
}

.btn-outline-info:hover {
  color: #1c1c1c;
  background-color: #f0f4f0;
  border-color: #f0f4f0;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(240, 244, 240, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #f0f4f0;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #1c1c1c;
  background-color: #f0f4f0;
  border-color: #f0f4f0;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(240, 244, 240, 0.5);
}

.btn-outline-warning {
  color: #fdf2df;
  border-color: #fdf2df;
}

.btn-outline-warning:hover {
  color: #1c1c1c;
  background-color: #fdf2df;
  border-color: #fdf2df;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(253, 242, 223, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fdf2df;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #1c1c1c;
  background-color: #fdf2df;
  border-color: #fdf2df;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(253, 242, 223, 0.5);
}

.btn-outline-danger {
  color: #f7e8e9;
  border-color: #f7e8e9;
}

.btn-outline-danger:hover {
  color: #1c1c1c;
  background-color: #f7e8e9;
  border-color: #f7e8e9;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(247, 232, 233, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f7e8e9;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #1c1c1c;
  background-color: #f7e8e9;
  border-color: #f7e8e9;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(247, 232, 233, 0.5);
}

.btn-outline-light {
  color: #f9f9f9;
  border-color: #f9f9f9;
}

.btn-outline-light:hover {
  color: #1c1c1c;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(249, 249, 249, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f9f9f9;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #1c1c1c;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(249, 249, 249, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #038203;
  text-decoration: none;
}

.btn-link:hover {
  color: #038203;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #616161;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #1c1c1c;
  text-align: center;
  white-space: nowrap;
  background-color: none;
  border: 1px solid #8c8c8c;
  border-radius: 0;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.5rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #1a5336 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0e2c1d !important;
}

.bg-secondary {
  background-color: #ff9500 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #cc7700 !important;
}

.bg-success {
  background-color: #f0f4f0 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #d3ded3 !important;
}

.bg-info {
  background-color: #f0f4f0 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #d3ded3 !important;
}

.bg-warning {
  background-color: #fdf2df !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fadfaf !important;
}

.bg-danger {
  background-color: #f7e8e9 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #eac2c5 !important;
}

.bg-light {
  background-color: #f9f9f9 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e0e0e0 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #cccccc !important;
}

.border-top {
  border-top: 1px solid #cccccc !important;
}

.border-right {
  border-right: 1px solid #cccccc !important;
}

.border-bottom {
  border-bottom: 1px solid #cccccc !important;
}

.border-left {
  border-left: 1px solid #cccccc !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1a5336 !important;
}

.border-secondary {
  border-color: #ff9500 !important;
}

.border-success {
  border-color: #f0f4f0 !important;
}

.border-info {
  border-color: #f0f4f0 !important;
}

.border-warning {
  border-color: #fdf2df !important;
}

.border-danger {
  border-color: #f7e8e9 !important;
}

.border-light {
  border-color: #f9f9f9 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1024px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1024px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 1024px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1024px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 1024px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #1a5336 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #081910 !important;
}

.text-secondary {
  color: #ff9500 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #b36800 !important;
}

.text-success {
  color: #f0f4f0 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #c4d4c4 !important;
}

.text-info {
  color: #f0f4f0 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #c4d4c4 !important;
}

.text-warning {
  color: #fdf2df !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #f9d597 !important;
}

.text-danger {
  color: #f7e8e9 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #e3afb3 !important;
}

.text-light {
  color: #f9f9f9 !important;
}

a.text-light:hover, a.text-light:focus {
  color: lightgray !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #1c1c1c !important;
}

.text-muted {
  color: #616161 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #bdbdbd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 1200px !important;
  }
  .container {
    min-width: 1200px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e1e1e1 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #cccccc;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #cccccc;
  }
}

/**
 * TD Emerald Standards
 * Core framework & elements
 **/
/* ******** Default Font ************ */
@font-face {
  font-family: "TD Graphik Light";
  src: url("../fonts/TDGraphik-Light-Web.eot");
  src: url("../fonts/TDGraphik-Light-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-Light-Web.woff2") format("woff2"), url("../fonts/TDGraphik-Light-Web.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik SemiLight";
  src: url("../fonts/TDGraphik-Semilight-Web.eot");
  src: url("../fonts/TDGraphik-Semilight-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-Semilight-Web.woff2") format("woff2"), url("../fonts/TDGraphik-Semilight-Web.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik Regular";
  src: url("../fonts/TDGraphik-Regular-Web.eot");
  src: url("../fonts/TDGraphik-Regular-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-Regular-Web.woff2") format("woff2"), url("../fonts/TDGraphik-Regular-Web.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik Medium";
  src: url("../fonts/TDGraphik-Medium-Web.eot");
  src: url("../fonts/TDGraphik-Medium-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-Medium-Web.woff2") format("woff2"), url("../fonts/TDGraphik-Medium-Web.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik Semibold";
  src: url("../fonts/TDGraphik-Semibold-Web.eot");
  src: url("../fonts/TDGraphik-Semibold-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-Semibold-Web.woff2") format("woff2"), url("../fonts/TDGraphik-Semibold-Web.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik Bold";
  src: url("../fonts/TDGraphik-Bold-Web.eot");
  src: url("../fonts/TDGraphik-Bold-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-Bold-Web.woff2") format("woff2"), url("../fonts/TDGraphik-Bold-Web.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik Light Italic";
  src: url("../fonts/TDGraphik-LightItalic-Web.eot");
  src: url("../fonts/TDGraphik-LightItalic-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-LightItalic-Web.woff2") format("woff2"), url("../fonts/TDGraphik-LightItalic-Web.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik SemiLight Italic";
  src: url("../fonts/TDGraphik-SemilightItalic-Web.eot");
  src: url("../fonts/TDGraphik-SemilightItalic-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-SemilightItalic-Web.woff2") format("woff2"), url("../fonts/TDGraphik-SemilightItalic-Web.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik Regular Italic";
  src: url("../fonts/TDGraphik-RegularItalic-Web.eot");
  src: url("../fonts/TDGraphik-RegularItalic-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-RegularItalic-Web.woff2") format("woff2"), url("../fonts/TDGraphik-RegularItalic-Web.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik Medium Italic";
  src: url("../fonts/TDGraphik-MediumItalic-Web.eot");
  src: url("../fonts/TDGraphik-MediumItalic-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-MediumItalic-Web.woff2") format("woff2"), url("../fonts/TDGraphik-MediumItalic-Web.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik Semibold Italic";
  src: url("../fonts/TDGraphik-SemiboldItalic-Web.eot");
  src: url("../fonts/TDGraphik-SemiboldItalic-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-SemiboldItalic-Web.woff2") format("woff2"), url("../fonts/TDGraphik-SemiboldItalic-Web.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "TD Graphik Bold Italic";
  src: url("../fonts/TDGraphik-BoldItalic-Web.eot");
  src: url("../fonts/TDGraphik-BoldItalic-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/TDGraphik-BoldItalic-Web.woff2") format("woff2"), url("../fonts/TDGraphik-BoldItalic-Web.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/****
 * THIS FILE IS GENERATED AUTOMATICALLY - DO NOT EDIT DIRECTLY - SEE "build/icons-update.js"
 */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons/icons.eot?aovo8b");
  src: url("../fonts/icons/icons.eot?aovo8b#iefix") format("embedded-opentype"), url("../fonts/icons/icons.woff2?aovo8b") format("woff2"), url("../fonts/icons/icons.ttf?aovo8b") format("truetype"), url("../fonts/icons/icons.woff?aovo8b") format("woff"), url("../fonts/icons/icons.svg?aovo8b#icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.td-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.td-icon-balance:before {
  content: "";
}

.td-icon-dayFinances:before {
  content: "";
}

.td-icon-dynamicPreformance:before {
  content: "";
}

.td-icon-easySafety:before {
  content: "";
}

.td-icon-exit:before {
  content: "";
}

.td-icon-growth:before {
  content: "";
}

.td-icon-growthIncome:before {
  content: "";
}

.td-icon-income:before {
  content: "";
}

.td-icon-incomeGrowth:before {
  content: "";
}

.td-icon-mixedPortfolio:before {
  content: "";
}

.td-icon-na:before {
  content: "";
}

.td-icon-noSaving:before {
  content: "";
}

.td-icon-overdraftProtection:before {
  content: "";
}

.td-icon-savePortion:before {
  content: "";
}

.td-icon-saveWhenever:before {
  content: "";
}

.td-icon-slowSteady:before {
  content: "";
}

.td-icon-AccidentSickness:before {
  content: "";
}

.td-icon-FileAClaim:before {
  content: "";
}

.td-icon-GetAQuote:before {
  content: "";
}

.td-icon-GetAQuote02:before {
  content: "";
}

.td-icon-IRA:before {
  content: "";
}

.td-icon-Login:before {
  content: "";
}

.td-icon-MBNARewards:before {
  content: "";
}

.td-icon-POSSolutions:before {
  content: "";
}

.td-icon-RDSP:before {
  content: "";
}

.td-icon-RIF:before {
  content: "";
}

.td-icon-TD:before {
  content: "";
}

.td-icon-TDApp:before {
  content: "";
}

.td-icon-TDEasyWeb:before {
  content: "";
}

.td-icon-TDShield:before {
  content: "";
}

.td-icon-TDdirectInvesting:before {
  content: "";
}

.td-icon-TDwealth:before {
  content: "";
}

.td-icon-accidentReports:before {
  content: "";
}

.td-icon-accounts:before {
  content: "";
}

.td-icon-activateCard:before {
  content: "";
}

.td-icon-aeroplan:before {
  content: "";
}

.td-icon-aeroplan2:before {
  content: "";
}

.td-icon-aeroplanRondel:before {
  content: "";
}

.td-icon-agriculture:before {
  content: "";
}

.td-icon-all:before {
  content: "";
}

.td-icon-allCalculatorTools:before {
  content: "";
}

.td-icon-allProducts:before {
  content: "";
}

.td-icon-apple:before {
  content: "";
}

.td-icon-applyForFunding:before {
  content: "";
}

.td-icon-approved:before {
  content: "";
}

.td-icon-arrowDown2:before {
  content: "";
}

.td-icon-arrowLeft:before {
  content: "";
}

.td-icon-arrowLeft2:before {
  content: "";
}

.td-icon-arrowRight:before {
  content: "";
}

.td-icon-arrowRight2:before {
  content: "";
}

.td-icon-arrowUp2:before {
  content: "";
}

.td-icon-atm:before {
  content: "";
}

.td-icon-attractNewCustomers:before {
  content: "";
}

.td-icon-atv:before {
  content: "";
}

.td-icon-auto:before {
  content: "";
}

.td-icon-avatar:before {
  content: "";
}

.td-icon-balanceTransfer:before {
  content: "";
}

.td-icon-banking:before {
  content: "";
}

.td-icon-bath:before {
  content: "";
}

.td-icon-bed:before {
  content: "";
}

.td-icon-bicycle:before {
  content: "";
}

.td-icon-bookAppointment:before {
  content: "";
}

.td-icon-bookAtBranch:before {
  content: "";
}

.td-icon-borrowing:before {
  content: "";
}

.td-icon-borrowingSolutions:before {
  content: "";
}

.td-icon-branch:before {
  content: "";
}

.td-icon-branchATM:before {
  content: "";
}

.td-icon-business:before {
  content: "";
}

.td-icon-businessChecklist:before {
  content: "";
}

.td-icon-businessOwner:before {
  content: "";
}

.td-icon-cantFindTopic:before {
  content: "";
}

.td-icon-car:before {
  content: "";
}

.td-icon-careers:before {
  content: "";
}

.td-icon-cashBack:before {
  content: "";
}

.td-icon-cashFinances:before {
  content: "";
}

.td-icon-cashflow:before {
  content: "";
}

.td-icon-cashflowImprovement:before {
  content: "";
}

.td-icon-certificateDeposit:before {
  content: "";
}

.td-icon-chat:before {
  content: "";
}

.td-icon-checkMark:before {
  content: "";
}

.td-icon-chequeing:before {
  content: "";
}

.td-icon-chequeing2:before {
  content: "";
}

.td-icon-circle:before {
  content: "";
}

.td-icon-claims:before {
  content: "";
}

.td-icon-clearButton:before {
  content: "";
}

.td-icon-close:before {
  content: "";
}

.td-icon-coinsHigh:before {
  content: "";
}

.td-icon-coinsLow:before {
  content: "";
}

.td-icon-coinsMiddle:before {
  content: "";
}

.td-icon-collapse:before {
  content: "";
}

.td-icon-communityNonprofit:before {
  content: "";
}

.td-icon-condo:before {
  content: "";
}

.td-icon-contactUs:before {
  content: "";
}

.td-icon-corporateResponsibility:before {
  content: "";
}

.td-icon-corporation:before {
  content: "";
}

.td-icon-coverage:before {
  content: "";
}

.td-icon-coverageExtra:before {
  content: "";
}

.td-icon-coverageMandatory:before {
  content: "";
}

.td-icon-coverageModify:before {
  content: "";
}

.td-icon-coverageSpecial:before {
  content: "";
}

.td-icon-creditProtection:before {
  content: "";
}

.td-icon-creditcards:before {
  content: "";
}

.td-icon-customers:before {
  content: "";
}

.td-icon-dashboard:before {
  content: "";
}

.td-icon-debt-en:before {
  content: "";
}

.td-icon-debt-fr:before {
  content: "";
}

.td-icon-debtConsolidation:before {
  content: "";
}

.td-icon-delete:before {
  content: "";
}

.td-icon-discount:before {
  content: "";
}

.td-icon-distributionGuide:before {
  content: "";
}

.td-icon-downCaret:before {
  content: "";
}

.td-icon-download:before {
  content: "";
}

.td-icon-downloadBusinessPlanTemplate:before {
  content: "";
}

.td-icon-downloadCertificate:before {
  content: "";
}

.td-icon-earthquake:before {
  content: "";
}

.td-icon-economics:before {
  content: "";
}

.td-icon-edit2:before {
  content: "";
}

.td-icon-edits:before {
  content: "";
}

.td-icon-email:before {
  content: "";
}

.td-icon-employees:before {
  content: "";
}

.td-icon-ensureCompliance:before {
  content: "";
}

.td-icon-equities:before {
  content: "";
}

.td-icon-error:before {
  content: "";
}

.td-icon-etf:before {
  content: "";
}

.td-icon-etf-fr:before {
  content: "";
}

.td-icon-exchangeRateForCash:before {
  content: "";
}

.td-icon-exchangeRateForNonCash:before {
  content: "";
}

.td-icon-expand:before {
  content: "";
}

.td-icon-expandedBusinessServices:before {
  content: "";
}

.td-icon-facebook:before {
  content: "";
}

.td-icon-faqs:before {
  content: "";
}

.td-icon-feedback:before {
  content: "";
}

.td-icon-filter:before {
  content: "";
}

.td-icon-financeSmallBusiness:before {
  content: "";
}

.td-icon-financialPlanning:before {
  content: "";
}

.td-icon-findAcreditcard:before {
  content: "";
}

.td-icon-findTaxEfficiencies:before {
  content: "";
}

.td-icon-firstVehicle:before {
  content: "";
}

.td-icon-fixedIncome:before {
  content: "";
}

.td-icon-flexibleMortgageFeatures:before {
  content: "";
}

.td-icon-forms:before {
  content: "";
}

.td-icon-franchise:before {
  content: "";
}

.td-icon-fraud:before {
  content: "";
}

.td-icon-generateIncome:before {
  content: "";
}

.td-icon-geolocation:before {
  content: "";
}

.td-icon-gicTermDeposits:before {
  content: "";
}

.td-icon-giftcardLoyalty:before {
  content: "";
}

.td-icon-government:before {
  content: "";
}

.td-icon-graduationCap:before {
  content: "";
}

.td-icon-growMyMoney:before {
  content: "";
}

.td-icon-growYourBusiness:before {
  content: "";
}

.td-icon-growingFamily:before {
  content: "";
}

.td-icon-growingFamily2:before {
  content: "";
}

.td-icon-hamburger:before {
  content: "";
}

.td-icon-handicap:before {
  content: "";
}

.td-icon-healthCarePractitioners:before {
  content: "";
}

.td-icon-help:before {
  content: "";
}

.td-icon-home:before {
  content: "";
}

.td-icon-home2:before {
  content: "";
}

.td-icon-homeEquityFlexline:before {
  content: "";
}

.td-icon-homepage:before {
  content: "";
}

.td-icon-hours:before {
  content: "";
}

.td-icon-house:before {
  content: "";
}

.td-icon-howTo:before {
  content: "";
}

.td-icon-idea:before {
  content: "";
}

.td-icon-identityTheft:before {
  content: "";
}

.td-icon-improveSales:before {
  content: "";
}

.td-icon-inbox:before {
  content: "";
}

.td-icon-increaseBusinessProductivity:before {
  content: "";
}

.td-icon-information:before {
  content: "";
}

.td-icon-insights:before {
  content: "";
}

.td-icon-instagram:before {
  content: "";
}

.td-icon-insurance:before {
  content: "";
}

.td-icon-international:before {
  content: "";
}

.td-icon-investTradeOnline:before {
  content: "";
}

.td-icon-investing:before {
  content: "";
}

.td-icon-investingPlatforms:before {
  content: "";
}

.td-icon-investmentTransfers:before {
  content: "";
}

.td-icon-investmentTypes:before {
  content: "";
}

.td-icon-investorRelations:before {
  content: "";
}

.td-icon-languagesSpoken:before {
  content: "";
}

.td-icon-leftCaret:before {
  content: "";
}

.td-icon-life:before {
  content: "";
}

.td-icon-lineOfCredit:before {
  content: "";
}

.td-icon-linkedin:before {
  content: "";
}

.td-icon-loanCalculator:before {
  content: "";
}

.td-icon-location:before {
  content: "";
}

.td-icon-location-coverage:before {
  content: "";
}

.td-icon-locationFeatures:before {
  content: "";
}

.td-icon-logout:before {
  content: "";
}

.td-icon-lowInterest:before {
  content: "";
}

.td-icon-maintenance:before {
  content: "";
}

.td-icon-makeAMajorPurchase:before {
  content: "";
}

.td-icon-manageBusiness:before {
  content: "";
}

.td-icon-manageMoney:before {
  content: "";
}

.td-icon-managingEmployees:before {
  content: "";
}

.td-icon-mapMarker:before {
  content: "";
}

.td-icon-mapMarkerFilled:before {
  content: "";
}

.td-icon-marine:before {
  content: "";
}

.td-icon-mobilePhone:before {
  content: "";
}

.td-icon-mortgages:before {
  content: "";
}

.td-icon-motorHome:before {
  content: "";
}

.td-icon-motorcycle:before {
  content: "";
}

.td-icon-mutualFunds:before {
  content: "";
}

.td-icon-myAdvantage:before {
  content: "";
}

.td-icon-myPolicies:before {
  content: "";
}

.td-icon-myTD:before {
  content: "";
}

.td-icon-navigateVolatility:before {
  content: "";
}

.td-icon-newCouple:before {
  content: "";
}

.td-icon-newCouple2:before {
  content: "";
}

.td-icon-newHome:before {
  content: "";
}

.td-icon-newPage:before {
  content: "";
}

.td-icon-newRevenueStreams:before {
  content: "";
}

.td-icon-newToCanada:before {
  content: "";
}

.td-icon-noFee:before {
  content: "";
}

.td-icon-offers:before {
  content: "";
}

.td-icon-oilDamage:before {
  content: "";
}

.td-icon-onlinePreapproval:before {
  content: "";
}

.td-icon-onlineSalesSolutions:before {
  content: "";
}

.td-icon-options:before {
  content: "";
}

.td-icon-orderByPhone:before {
  content: "";
}

.td-icon-orderCashOnline:before {
  content: "";
}

.td-icon-orderStatus:before {
  content: "";
}

.td-icon-otherServices:before {
  content: "";
}

.td-icon-outlinedCircleClose:before {
  content: "";
}

.td-icon-overlayLink:before {
  content: "";
}

.td-icon-payBillOnline:before {
  content: "";
}

.td-icon-payBills:before {
  content: "";
}

.td-icon-payUSBill:before {
  content: "";
}

.td-icon-paymentsTransfers:before {
  content: "";
}

.td-icon-pending:before {
  content: "";
}

.td-icon-person:before {
  content: "";
}

.td-icon-personal:before {
  content: "";
}

.td-icon-pinterest:before {
  content: "";
}

.td-icon-preciousMetals:before {
  content: "";
}

.td-icon-preferredAutoVendors:before {
  content: "";
}

.td-icon-prepaidCard:before {
  content: "";
}

.td-icon-priceTag:before {
  content: "";
}

.td-icon-printer:before {
  content: "";
}

.td-icon-products:before {
  content: "";
}

.td-icon-productsAndServices:before {
  content: "";
}

.td-icon-profitIncrease:before {
  content: "";
}

.td-icon-question:before {
  content: "";
}

.td-icon-quote:before {
  content: "";
}

.td-icon-readyCommitment:before {
  content: "";
}

.td-icon-registeredAccounts:before {
  content: "";
}

.td-icon-registrationPasswords:before {
  content: "";
}

.td-icon-renters:before {
  content: "";
}

.td-icon-renters-fr:before {
  content: "";
}

.td-icon-replacementCard:before {
  content: "";
}

.td-icon-requestCertificate:before {
  content: "";
}

.td-icon-resourceCentre:before {
  content: "";
}

.td-icon-retirementPlanning:before {
  content: "";
}

.td-icon-rightCaret:before {
  content: "";
}

.td-icon-saveForSchool:before {
  content: "";
}

.td-icon-savingAndInvesting:before {
  content: "";
}

.td-icon-savings:before {
  content: "";
}

.td-icon-search:before {
  content: "";
}

.td-icon-secondVehicle:before {
  content: "";
}

.td-icon-secureBtn:before {
  content: "";
}

.td-icon-security:before {
  content: "";
}

.td-icon-selectADate:before {
  content: "";
}

.td-icon-sendFeedback:before {
  content: "";
}

.td-icon-sendMoney:before {
  content: "";
}

.td-icon-sendMoneyInternationally:before {
  content: "";
}

.td-icon-share:before {
  content: "";
}

.td-icon-shopping:before {
  content: "";
}

.td-icon-smallBusiness:before {
  content: "";
}

.td-icon-snowmobile:before {
  content: "";
}

.td-icon-solutions:before {
  content: "";
}

.td-icon-star:before {
  content: "";
}

.td-icon-startClaim:before {
  content: "";
}

.td-icon-startOver:before {
  content: "";
}

.td-icon-startingUp:before {
  content: "";
}

.td-icon-startingUp-fr:before {
  content: "";
}

.td-icon-startup:before {
  content: "";
}

.td-icon-student:before {
  content: "";
}

.td-icon-successionPlanning:before {
  content: "";
}

.td-icon-superlock:before {
  content: "";
}

.td-icon-swapLocations:before {
  content: "";
}

.td-icon-switchToTDMortgage:before {
  content: "";
}

.td-icon-takeAPhoto:before {
  content: "";
}

.td-icon-takeAPhotoOutlined:before {
  content: "";
}

.td-icon-taxFreeAccounts:before {
  content: "";
}

.td-icon-tdInsuranceCard:before {
  content: "";
}

.td-icon-tdRewards:before {
  content: "";
}

.td-icon-toOurCustomers:before {
  content: "";
}

.td-icon-todaysRates:before {
  content: "";
}

.td-icon-tools:before {
  content: "";
}

.td-icon-tooltip:before {
  content: "";
}

.td-icon-trade:before {
  content: "";
}

.td-icon-tradingAccounts:before {
  content: "";
}

.td-icon-transfer:before {
  content: "";
}

.td-icon-transit:before {
  content: "";
}

.td-icon-travel:before {
  content: "";
}

.td-icon-travelRewards:before {
  content: "";
}

.td-icon-truck:before {
  content: "";
}

.td-icon-tutorials:before {
  content: "";
}

.td-icon-twitter:before {
  content: "";
}

.td-icon-unexpectedExpenses:before {
  content: "";
}

.td-icon-unsureHome:before {
  content: "";
}

.td-icon-upCaret:before {
  content: "";
}

.td-icon-usDollar:before {
  content: "";
}

.td-icon-vacation:before {
  content: "";
}

.td-icon-vendor:before {
  content: "";
}

.td-icon-videoPlayButton:before {
  content: "";
}

.td-icon-videoPlayButtonCircle:before {
  content: "";
}

.td-icon-walking:before {
  content: "";
}

.td-icon-wallet:before {
  content: "";
}

.td-icon-warning:before {
  content: "";
}

.td-icon-watchlists:before {
  content: "";
}

.td-icon-waterDamage:before {
  content: "";
}

.td-icon-wealthAdvise:before {
  content: "";
}

.td-icon-webBroker:before {
  content: "";
}

.td-icon-whatsNew:before {
  content: "";
}

.td-icon-wifi:before {
  content: "";
}

.td-icon-womenAndHealth:before {
  content: "";
}

.td-icon-workplace:before {
  content: "";
}

.td-icon-writeBusinessPlan:before {
  content: "";
}

.td-icon-youngAndSingle:before {
  content: "";
}

.td-icon-youthAccount:before {
  content: "";
}

.td-icon-youtube:before {
  content: "";
}

.td-icon-wrapper {
  display: inline-block;
  text-align: center;
  position: relative;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.td-icon-wrapper.td-interactive-icon {
  text-decoration: none;
  outline: none;
  border: solid 2px #bdbdbd;
}

.td-icon-wrapper.td-interactive-icon.clicked {
  background-color: #008a00;
  border-color: #008a00;
}

.td-icon-wrapper.td-interactive-icon.clicked .td-icon {
  color: #fff;
}

.td-icon-wrapper.td-interactive-icon.icon-small {
  border-width: 1px;
}

.td-icon-wrapper.td-interactive-icon.icon-regular {
  border-width: 2px;
}

.td-icon-wrapper.td-interactive-icon.icon-medium {
  border-width: 2px;
}

.td-icon-wrapper.td-interactive-icon.icon-large {
  border-width: 3px;
}

.td-icon-wrapper.td-interactive-icon .td-icon {
  color: #008a00;
}

.td-icon-wrapper.td-interactive-icon:hover, .td-icon-wrapper.td-interactive-icon:focus {
  border-color: #008a00;
}

.td-icon-wrapper.td-interactive-icon:active {
  border-color: #008a00;
  background-color: #008a00;
  outline: none;
}

.td-icon-wrapper.td-interactive-icon:active .td-icon {
  color: #fff;
}

.td-icon-wrapper.td-interactive-icon.td-background-darkgreen {
  border-color: #008a00;
}

.td-icon-wrapper.td-interactive-icon.td-background-darkgreen .td-icon {
  color: #fff;
}

.td-icon-wrapper.td-interactive-icon.td-background-darkgreen:active {
  background-color: #008a00;
}

.td-icon-wrapper.td-interactive-icon.td-background-darkgreen:hover, .td-icon-wrapper.td-interactive-icon.td-background-darkgreen:focus {
  border-color: #008a00;
  background-color: #008a00;
}

.td-icon-wrapper.td-interactive-icon.td-background-brightgreen {
  background-color: #008a00;
  border: none;
}

.td-icon-wrapper.td-interactive-icon.td-background-orange {
  background-color: #ff9500;
  border: none;
}

.td-icon-wrapper .td-icon {
  color: #008a00;
  line-height: 1.42857;
}

.td-icon-wrapper.icon-small {
  height: 30px;
  width: 30px;
  border-width: 1px;
}

.td-icon-wrapper.icon-small .td-icon {
  font-size: 21px;
}

.td-icon-wrapper.icon-regular {
  height: 50px;
  width: 50px;
  border-width: 2px;
}

.td-icon-wrapper.icon-regular .td-icon {
  font-size: 35px;
}

.td-icon-wrapper.icon-medium {
  height: 75px;
  width: 75px;
  border-width: 2px;
}

.td-icon-wrapper.icon-medium .td-icon {
  font-size: 50px;
}

.td-icon-wrapper.icon-large {
  height: 100px;
  width: 100px;
  border-width: 2px;
}

.td-icon-wrapper.icon-large .td-icon {
  font-size: 70px;
}

.td-icon-wrapper.icon-xlarge {
  height: 150px;
  width: 150px;
  border-width: 2px;
}

.td-icon-wrapper.icon-xlarge .td-icon {
  font-size: 100px;
}

.td-icon-wrapper.td-background-darkgreen {
  border-color: #008a00;
  text-decoration: none;
}

.td-icon-wrapper.td-background-darkgreen .td-icon {
  color: #fff;
}

.td-icon-wrapper.td-background-darkgreen.td-icon-border-dark {
  border-color: #1a5336;
}

.td-icon {
  font-family: icons;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  position: relative;
  text-decoration: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.td-icon.icon-small {
  font-size: 20px;
  line-height: 1;
}

.td-icon.icon-regular {
  font-size: 35px;
  line-height: 1;
}

.td-icon.icon-medium {
  font-size: 70px;
  line-height: 1;
}

.td-icon.icon-large {
  font-size: 140px;
  line-height: 1;
}

.td-icon:before {
  width: 1em;
  text-align: center;
  display: inline-block;
}

.td-icon.td-icon-variable-width:before {
  width: auto;
}

.td-icon-colour-primary {
  color: #1a5336;
}

@media aural {
  .td-icon {
    speak: none;
  }
}

a .td-icon {
  text-decoration: none;
}

a.td-icon-link {
  text-decoration: none;
}

.td-icon-wrapper:hover .td-icon:not(:hover) {
  color: #008a00;
}

.td-icon-wrapper:hover.td-background-darkgreen .td-icon:not(:hover) {
  border-color: #008a00;
  color: #fff;
}

.td-icon-wrapper:active.td-background-darkgreen .td-icon:not(:hover) {
  background-color: #008a00;
}

.td-icon.td-icon-sort::before, .td-icon.td-icon-sort::after {
  border-left: 0.25em solid transparent;
  border-right: 0.25em solid transparent;
  content: '';
  display: inline-block;
  width: auto;
  margin-left: 0.25em;
  margin-right: 0.25em;
  transition: all 150ms ease-in-out;
}

.td-icon.td-icon-sort::before {
  border-bottom: 0.25em solid currentColor;
  margin-bottom: 0.5em;
}

.td-icon.td-icon-sort::after {
  border-top: 0.25em solid currentColor;
  position: absolute;
  bottom: 0.185em;
  left: 0;
}

.td-icon.td-icon-sort.td-icon-sort-asc::after {
  opacity: 0.35;
}

.td-icon.td-icon-sort.td-icon-sort-desc::before {
  opacity: 0.35;
}

.td-icon.td-icon-sort.td-icon-variable-width::before, .td-icon.td-icon-sort.td-icon-variable-width::after {
  margin-left: 0;
  margin-right: 0;
}

body {
  font-size: 0.9375rem;
  line-height: 1.5334;
  font-variant-ligatures: no-contextual;
}

b,
strong {
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

sup {
  font-size: 0.625rem;
  top: -0.75em;
  vertical-align: baseline;
  position: relative;
}

h1, .h1 {
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1.3077;
  color: #1a5336;
  letter-spacing: -0.03125rem;
}

h1 sup, .h1 sup {
  font-size: 0.8125rem;
  top: -1rem;
}

h2, .h2 {
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1.41;
  color: #1a5336;
  letter-spacing: -0.03125rem;
}

h2 sup, .h2 sup {
  font-size: 0.8125rem;
  top: -0.75rem;
}

h3, .h3 {
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1.556;
  letter-spacing: -0.03125rem;
}

h3 sup, .h3 sup {
  font-size: 0.8125rem;
  top: -0.65rem;
}

.td-legal-copy {
  font-size: 0.6875rem;
  line-height: 1.364;
}

.td-small-copy {
  font-size: 0.8125rem;
  line-height: 1.385;
}

/* Large, XL, XXL Text */
.td-large-copy {
  font-size: 1.4375rem;
  line-height: 1.35;
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.01em;
}

.td-xl-copy {
  font-size: 2.1875rem;
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.025em;
}

.td-xxl-copy {
  font-size: 3rem;
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.025em;
}

/* Tighter Line Height */
.lh-sm {
  line-height: 1.2 !important;
}

p a,
sup a,
sub a,
ul.td-list a,
ol.td-list a,
dl a,
a.td-link-inline {
  font-weight: bold;
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a.td-link-nounderline {
  text-decoration: none;
}

a.td-link-nounderline:hover, a.td-link-nounderline:focus {
  text-decoration: none;
}

.td-link-action:hover::before, .td-link-action:hover::after,
.td-link-previous:hover::before,
.td-link-previous:hover::after {
  text-decoration: underline;
}

a.td-link-action,
a.td-link-previous {
  position: relative;
  display: inline-block;
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a.td-link-action:before, a.td-link-action:after,
a.td-link-previous:before,
a.td-link-previous:after {
  font-size: 70%;
  font-family: "icons";
  vertical-align: middle;
  line-height: 1;
  width: 1em;
  text-align: center;
  display: inline-block;
}

a.td-link-action:hover::before, a.td-link-action:hover::after,
a.td-link-previous:hover::before,
a.td-link-previous:hover::after {
  text-decoration: none;
}

a.td-link-action {
  padding-right: calc(1em + 4px);
}

a.td-link-action::after {
  margin-left: 4px;
  margin-right: calc(-1em - 4px);
  content: "";
}

@supports (content: "x"/"y") {
  a.td-link-action::after {
    content: ""/"";
  }
}

@supports (alt: "") {
  a.td-link-action::after {
    alt: "";
  }
}

a.td-link-previous::before {
  margin-right: 6px;
  margin-left: -2px;
  position: relative;
  top: -.1em;
  content: "";
}

@supports (content: "x"/"y") {
  a.td-link-previous::before {
    content: ""/"";
  }
}

@supports (alt: "") {
  a.td-link-previous::before {
    alt: "";
  }
}

a.td-cta {
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  line-height: 1.5;
}

a.td-cta.td-link-action::after {
  font-size: 1rem;
  margin-top: -1px;
}

a.td-cta > .td-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
}

a.td-cta > .td-icon,
h3.td-cta > a > .td-icon {
  font-weight: normal;
  text-shadow: 0.5px 0 0 currentColor, -0.5px 0 0 currentColor, 0 0.5px 0 currentColor, 0 -0.5px 0 currentColor;
}

a.td-link-action::after,
a.td-link-previous::before,
a.td-link-standalone .td-link-lastword .td-icon::before,
a.td-link-back .td-link-firstword > .td-icon::before {
  font-weight: normal;
  text-shadow: 0.5px 0 0 currentColor, -0.5px 0 0 currentColor, 0 0.5px 0 currentColor, 0 -0.5px 0 currentColor;
}

.btn {
  position: relative;
  color: #fff;
}

.btn::before {
  border-radius: 0rem;
  border: 2px solid #fff;
  bottom: -2px;
  content: '';
  display: block;
  left: -2px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: -2px;
  top: -2px;
  transition: all 150ms ease-in-out;
}

.btn.focus::before, .btn:focus::before {
  bottom: 3px;
  left: 3px;
  opacity: 0.75;
  right: 3px;
  top: 3px;
}

.btn:hover::before {
  opacity: 0;
}

.btn span.td-icon {
  display: inline-block !important;
  text-align: left;
  vertical-align: middle;
  font-size: 1.375rem;
  margin-top: -4px;
  color: inherit;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .btn span.td-icon {
    font-size: 1.25rem;
  }
}

.btn span.td-icon:before {
  line-height: 1;
  display: inline;
  padding-right: 15px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .btn span.td-icon:before {
    padding-right: 7px;
  }
}

.td-btn-CTA, .btn-primary {
  color: #1c1c1c;
  background-color: #ff9500;
  border-color: #ff9500;
}

.td-btn-CTA:hover, .btn-primary:hover {
  color: #fff;
  background-color: #e37609;
  border-color: #e37609;
}

.td-btn-CTA:focus, .td-btn-CTA.focus, .btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #e37609;
  border-color: #e37609;
  box-shadow: 0 0 0 0 rgba(221, 131, 4, 0.5);
}

.td-btn-CTA.disabled, .td-btn-CTA:disabled, .btn-primary.disabled, .btn-primary:disabled {
  color: #1c1c1c;
  background-color: #ff9500;
  border-color: #ff9500;
}

.td-btn-CTA:not(:disabled):not(.disabled):active, .td-btn-CTA:not(:disabled):not(.disabled).active,
.show > .td-btn-CTA.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #1c1c1c;
  background-color: #eca42e;
  border-color: #eca42e;
}

.td-btn-CTA:not(:disabled):not(.disabled):active:focus, .td-btn-CTA:not(:disabled):not(.disabled).active:focus,
.show > .td-btn-CTA.dropdown-toggle:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(221, 131, 4, 0.5);
}

.td-btn-CTA:hover, .btn-primary:hover {
  color: #1c1c1c;
}

.td-btn-CTA.hover, .btn-primary.hover {
  background-color: #e37609;
  border-color: #e37609;
}

.td-btn-primary-light, .btn-secondary {
  color: #fff;
  background-color: #008a00;
  border-color: #008a00;
}

.td-btn-primary-light:hover, .btn-secondary:hover {
  color: #fff;
  background-color: #0d7c16;
  border-color: #0d7c16;
}

.td-btn-primary-light:focus, .td-btn-primary-light.focus, .btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #0d7c16;
  border-color: #0d7c16;
  box-shadow: 0 0 0 0 rgba(38, 156, 38, 0.5);
}

.td-btn-primary-light.disabled, .td-btn-primary-light:disabled, .btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #008a00;
  border-color: #008a00;
}

.td-btn-primary-light:not(:disabled):not(.disabled):active, .td-btn-primary-light:not(:disabled):not(.disabled).active,
.show > .td-btn-primary-light.dropdown-toggle, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1a9630;
  border-color: #1a9630;
}

.td-btn-primary-light:not(:disabled):not(.disabled):active:focus, .td-btn-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .td-btn-primary-light.dropdown-toggle:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 156, 38, 0.5);
}

.td-btn-primary-light.hover, .btn-secondary.hover {
  background-color: #0d7c16;
  border-color: #0d7c16;
}

.td-btn-primary-dark {
  color: #fff;
  background-color: #1a5336;
  border-color: #1a5336;
}

.td-btn-primary-dark:hover {
  color: #fff;
  background-color: #14422c;
  border-color: #14422c;
}

.td-btn-primary-dark:focus, .td-btn-primary-dark.focus {
  color: #fff;
  background-color: #14422c;
  border-color: #14422c;
  box-shadow: 0 0 0 0 rgba(60, 109, 84, 0.5);
}

.td-btn-primary-dark.disabled, .td-btn-primary-dark:disabled {
  color: #fff;
  background-color: #1a5336;
  border-color: #1a5336;
}

.td-btn-primary-dark:not(:disabled):not(.disabled):active, .td-btn-primary-dark:not(:disabled):not(.disabled).active,
.show > .td-btn-primary-dark.dropdown-toggle {
  color: #fff;
  background-color: #30654b;
  border-color: #30654b;
}

.td-btn-primary-dark:not(:disabled):not(.disabled):active:focus, .td-btn-primary-dark:not(:disabled):not(.disabled).active:focus,
.show > .td-btn-primary-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(60, 109, 84, 0.5);
}

.td-btn-primary-dark:hover, .td-btn-primary-dark.hover {
  background-color: #14422c;
  border-color: #14422c;
}

.td-btn-secondary-clear {
  color: #fff;
  background-color: transparent;
  border-color: #008a00;
  color: #038203;
  border-width: 2px;
  padding: 0.4375rem 0.4375rem;
}

.td-btn-secondary-clear:hover {
  color: #1c1c1c;
  background-color: #ebebeb;
  border-color: #038203;
}

.td-btn-secondary-clear:focus, .td-btn-secondary-clear.focus {
  color: #1c1c1c;
  background-color: #ebebeb;
  border-color: #038203;
  box-shadow: 0 0 0 0 rgba(38, 156, 38, 0.5);
}

.td-btn-secondary-clear.disabled, .td-btn-secondary-clear:disabled {
  color: #fff;
  background-color: transparent;
  border-color: #008a00;
}

.td-btn-secondary-clear:not(:disabled):not(.disabled):active, .td-btn-secondary-clear:not(:disabled):not(.disabled).active,
.show > .td-btn-secondary-clear.dropdown-toggle {
  color: #1c1c1c;
  background-color: #dadada;
  border-color: #038203;
}

.td-btn-secondary-clear:not(:disabled):not(.disabled):active:focus, .td-btn-secondary-clear:not(:disabled):not(.disabled).active:focus,
.show > .td-btn-secondary-clear.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 156, 38, 0.5);
}

.td-btn-secondary-clear.hover, .td-btn-secondary-clear:hover {
  color: #038203;
  background-color: #ebebeb;
}

.td-btn-secondary-clear:not(:disabled):not(.disabled):active, .td-btn-secondary-clear:not(:disabled):not(.disabled).active {
  color: #038203;
}

.td-btn-secondary-clear::before {
  border-color: #008a00;
}

.td-btn-secondary-clear-white {
  color: #fff;
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
  border-width: 2px;
  padding: 0.4375rem 0.4375rem;
}

.td-btn-secondary-clear-white:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: #ffffff;
}

.td-btn-secondary-clear-white:focus, .td-btn-secondary-clear-white.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: #ffffff;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.td-btn-secondary-clear-white.disabled, .td-btn-secondary-clear-white:disabled {
  color: #fff;
  background-color: transparent;
  border-color: #ffffff;
}

.td-btn-secondary-clear-white:not(:disabled):not(.disabled):active, .td-btn-secondary-clear-white:not(:disabled):not(.disabled).active,
.show > .td-btn-secondary-clear-white.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #ffffff;
}

.td-btn-secondary-clear-white:not(:disabled):not(.disabled):active:focus, .td-btn-secondary-clear-white:not(:disabled):not(.disabled).active:focus,
.show > .td-btn-secondary-clear-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.td-btn-secondary-clear-white.hover, .td-btn-secondary-clear-white:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.1);
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  color: #cccccc;
  background-color: #f9f9f9;
  border-color: #cccccc;
  border-width: 2px;
  padding: 0.4375rem 0.4375rem;
}

.btn.disabled:hover, .btn.disabled:focus, .btn.disabled.focus, .btn[disabled]:hover, .btn[disabled]:focus, .btn[disabled].focus, fieldset[disabled] .btn:hover, fieldset[disabled] .btn:focus, fieldset[disabled] .btn.focus {
  color: #cccccc;
  background-color: #f9f9f9;
  border-color: #cccccc;
}

hr.td-thin-divider-line-1 {
  border-top: 1px solid #cccccc;
}

hr.td-thin-divider-line-2 {
  border-top: 1px solid #616161;
}

hr.td-thick-divider-line {
  border-top: 10px solid #f9f9f9;
}

.td-vertical-divider-line-1 {
  border-right: solid 1px;
  border-color: #cccccc;
}

.td-vertical-divider-line-2 {
  border-right: solid 1px;
  border-color: #616161;
}

.td-bg-colour-1 {
  background-color: #f9fcf4;
}

.td-bg-colour-2 {
  background-color: #f9f9f9;
}

.td-bg-colour-3 {
  background-color: #008a00;
}

.td-bg-colour-4 {
  background-color: #1a5336;
}

.td-bg-colour-5 {
  background-color: #ffffff;
}

.td-bg-colour-6 {
  background-color: #f6faf0;
}

.td-bg-colour-7 {
  background-color: #f3f3f3;
}

ul.td-list {
  list-style: none;
  padding-left: 1.25rem;
  text-align: left;
}

ul.td-list > li {
  margin-top: 0.3125rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 767.98px) {
  ul.td-list > li {
    margin-bottom: 0.3125rem;
  }
}

ul.td-list > li:before {
  color: #038203;
  font-family: "icons";
  content: "";
  font-size: 7px;
  margin-top: -0.1875rem;
  margin-left: -1.1875rem;
  padding-right: 0.8125rem;
  vertical-align: middle;
  display: inline-block;
}

ol.td-list {
  padding-left: 0.9375rem;
  text-align: left;
}

ol.td-list > li {
  padding-left: 0.5rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 767.98px) {
  ol.td-list > li {
    margin-bottom: 0.3125rem;
  }
}

.table th {
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.table > thead > tr > th {
  border-bottom: 1px solid #cccccc;
}

.table > thead th {
  background-color: #f9f9f9;
}

.table > thead > tr > th,
.table > thead > tr > td {
  padding: 15px;
}

.table.td-table-align-middle td, .table.td-table-align-middle th {
  vertical-align: middle;
}

.table-bordered {
  border-left: 0;
  border-right: 0;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom: 1px solid #cccccc;
}

.table-bordered td:first-child,
.table-bordered th:first-child {
  border-left: 0;
}

.table-bordered td:last-child,
.table-bordered th:last-child {
  border-right: 0;
}

@media (max-width: 767.98px) {
  .table-responsive {
    border: none;
  }
}

.td-flag {
  position: relative;
  display: inline-block;
  padding: 4px 15px;
  line-height: 1.5;
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.6875rem;
}

.td-flag.td-flag-special-offer {
  color: #ffffff;
  background-color: #008a00;
  border-color: #008a00;
}

.td-flag.td-flag-special-offer:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  border: 12px solid transparent;
  border-top-color: inherit;
  border-bottom-color: inherit;
  right: -12px;
}

.td-flag.td-flag-special-offer.td-flag-right:after {
  left: -12px;
  right: auto;
}

.td-flag.td-flag-recently-viewed {
  color: #1c1c1c;
  background-color: #f9fcf4;
  align-content: center;
  border-radius: 0 0 5px 0;
}

.td-flag.td-flag-recently-viewed.td-flag-right {
  border-radius: 0 0 0 5px;
}

.form-group .input-group {
  position: relative;
}

.form-group .input-group .input-group-prepend,
.form-group .input-group .input-group-append {
  display: block;
  position: absolute;
  top: 10px;
}

.form-group .input-group .input-group-prepend {
  left: 15px;
}

.form-group .input-group .input-group-append {
  right: 15px;
}

.form-group .input-group .input-group-text {
  border: none;
  padding: 0;
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.form-group .input-group .form-control {
  position: static;
  padding-left: 30px;
  padding-right: 35px;
}

.form-group .input-group .form-control:focus {
  z-index: auto;
}

.form-group.td-form-group-padding {
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 0;
}

.form-control {
  height: 42px;
  border-width: 1px;
  padding: 3px 15px;
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.9375rem;
}

.form-control:disabled, .form-control[readonly] {
  color: #cccccc;
  border-color: #cccccc;
  background-color: #f9f9f9;
}

.form-control:disabled:-moz-placeholder, .form-control[readonly]:-moz-placeholder {
  color: #cccccc;
}

.form-control:disabled::-moz-placeholder, .form-control[readonly]::-moz-placeholder {
  color: #cccccc;
}

.form-control:disabled:-ms-input-placeholder, .form-control[readonly]:-ms-input-placeholder {
  color: #cccccc;
}

.form-control:disabled::-ms-input-placeholder, .form-control[readonly]::-ms-input-placeholder {
  color: #cccccc;
}

.form-control:disabled::-webkit-input-placeholder, .form-control[readonly]::-webkit-input-placeholder {
  color: #cccccc;
}

@media (max-width: 767.98px) {
  .form-control {
    font-size: 1rem;
  }
}

.form-control:-moz-placeholder {
  font-family: "TD Graphik SemiLight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #767676;
}

.form-control::-moz-placeholder {
  font-family: "TD Graphik SemiLight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #767676;
}

.form-control:-ms-input-placeholder {
  font-family: "TD Graphik SemiLight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #767676;
}

.form-control::-ms-input-placeholder {
  font-family: "TD Graphik SemiLight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #767676;
}

.form-control::-webkit-input-placeholder {
  font-family: "TD Graphik SemiLight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #767676;
}

.form-control[type="radio"], .form-control[type="checkbox"] {
  height: auto;
}

.form-control:focus, .form-control:hover:not([disabled]) {
  border-bottom-width: 3px;
  padding-bottom: 1px;
  border-color: #8c8c8c;
  border-bottom-color: #008a00;
  outline: none;
}

fieldset[disabled] .form-control:hover {
  border-bottom-width: 1px;
  padding-bottom: 3px;
  border-color: #cccccc;
}

textarea.form-control {
  height: auto;
  resize: none;
  padding-top: 6px;
}

.td-select {
  position: relative;
}

.td-select select {
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -ms-user-select: none;
  appearance: none;
  padding-right: 34px;
  /* Remove IE native ui */
}

.td-select select::-ms-expand {
  display: none;
}

.td-select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.td-select select:focus::-ms-value {
  background-color: white;
  color: #000;
}

.td-select::after {
  content: "";
  pointer-events: none;
  position: absolute;
  font-size: 0.625em;
  line-height: 1;
  width: 0.5rem;
  height: 0.5rem;
  margin-top: -0.5em;
  top: 45%;
  right: 1.2em;
  color: #fff;
  border: 3px solid #000;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 2px;
  border-right-width: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}

.td-select:hover::after {
  border-color: #008a00;
}

.td-select.select-disabled::after {
  border-color: #cccccc;
}

.td-select.select-disabled select[disabled] {
  font-family: "TD Graphik SemiLight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.td-select.select-disabled:hover::after {
  border-color: #cccccc;
}

.td-select.is-invalid::after, .td-select.is-invalid:hover::after {
  border-color: #ae1100;
}

fieldset[disabled] .td-select.select-disabled select {
  font-family: "TD Graphik SemiLight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.form-check {
  position: relative;
  display: block;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 8px;
}

.form-check label {
  display: inline-block;
  height: 100%;
  padding: 2px 0 0 15px;
  margin: 0;
}

.form-check label::after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.form-check + .form-check {
  margin-top: 20px;
}

.form-check-inline {
  display: inline-block;
  margin-top: 10px;
  margin-right: 25px;
  vertical-align: top;
}

.form-check-inline + .form-check-inline {
  margin-top: 10px;
  margin-left: 0;
}

.form-check input[type="radio"],
.form-check input[type="checkbox"] {
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 0;
  margin: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: solid 2px #008a00;
  width: 20px;
  height: 20px;
  outline: none;
  cursor: pointer;
}

.form-check input[type="radio"]::-ms-check,
.form-check input[type="checkbox"]::-ms-check {
  border: solid 2px #008a00;
}

@-moz-document url-prefix() {
  .form-check input[type="radio"],
  .form-check input[type="checkbox"] {
    left: -1000px;
  }
  .form-check input[type="radio"] + label::before,
  .form-check input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    border: solid 2px #008a00;
  }
}

.form-check input[type="radio"]:hover,
.form-check input[type="checkbox"]:hover {
  background-color: #f9f9f9;
}

.form-check input[type="radio"]:hover::-ms-check,
.form-check input[type="checkbox"]:hover::-ms-check {
  background-color: #f9f9f9;
}

@-moz-document url-prefix() {
  .form-check input[type="radio"]:hover + label::before,
  .form-check input[type="checkbox"]:hover + label::before {
    background-color: #f9f9f9;
  }
}

.form-check input[type="radio"]:checked,
.form-check input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2219%22%20height%3D%2216%22%20viewBox%3D%220%200%2019%2016%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20fill%3A%20%2300a22e%3B%0A%20%20%20%20%20%20%20%20stroke%3A%20%23fff%3B%0A%20%20%20%20%20%20%20%20stroke-linejoin%3A%20round%3B%0A%20%20%20%20%20%20%20%20stroke-width%3A%202px%3B%0A%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%0A%20%20%20%20%20%20%20%20filter%3A%20url(%23filter)%3B%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%3C%2Fstyle%3E%0A%20%20%20%20%3Cfilter%20id%3D%22filter%22%20x%3D%222666%22%20y%3D%225985%22%20width%3D%2219%22%20height%3D%2216%22%20filterUnits%3D%22userSpaceOnUse%22%3E%0A%20%20%20%20%20%20%3CfeFlood%20result%3D%22flood%22%20flood-color%3D%22%23fff%22%2F%3E%0A%20%20%20%20%20%20%3CfeComposite%20result%3D%22composite%22%20operator%3D%22in%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%20%20%3CfeBlend%20result%3D%22blend%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%3C%2Ffilter%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Cg%20transform%3D%22translate(-2666%20-5985)%22%20style%3D%22fill%3A%20%2300a22e%3B%20filter%3A%20url(%23filter)%22%3E%0A%20%20%20%20%3Cpath%20id%3D%22checkmark%22%20class%3D%22cls-1%22%20d%3D%22M2673.31%2C5997.34l-0.78-.81%2C7.76-8.09%2C0.78%2C0.81Zm-3.85-4.11%2C0.82-.78%2C3.81%2C4.02-0.82.78Z%22%20style%3D%22stroke%3A%20inherit%3B%20filter%3A%20none%3B%20fill%3A%20inherit%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%20%20%3Cuse%20transform%3D%22translate(-2666%20-5985)%22%20xlink%3Ahref%3D%22%23checkmark%22%20style%3D%22stroke%3A%20%23fff%3B%20filter%3A%20none%3B%20fill%3A%20none%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-size: 18px 18px;
  background-color: #008a00;
  background-position: center;
}

.form-check input[type="radio"]:checked::-ms-check,
.form-check input[type="checkbox"]:checked::-ms-check {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2219%22%20height%3D%2216%22%20viewBox%3D%220%200%2019%2016%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20fill%3A%20%2300a22e%3B%0A%20%20%20%20%20%20%20%20stroke%3A%20%23fff%3B%0A%20%20%20%20%20%20%20%20stroke-linejoin%3A%20round%3B%0A%20%20%20%20%20%20%20%20stroke-width%3A%202px%3B%0A%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%0A%20%20%20%20%20%20%20%20filter%3A%20url(%23filter)%3B%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%3C%2Fstyle%3E%0A%20%20%20%20%3Cfilter%20id%3D%22filter%22%20x%3D%222666%22%20y%3D%225985%22%20width%3D%2219%22%20height%3D%2216%22%20filterUnits%3D%22userSpaceOnUse%22%3E%0A%20%20%20%20%20%20%3CfeFlood%20result%3D%22flood%22%20flood-color%3D%22%23fff%22%2F%3E%0A%20%20%20%20%20%20%3CfeComposite%20result%3D%22composite%22%20operator%3D%22in%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%20%20%3CfeBlend%20result%3D%22blend%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%3C%2Ffilter%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Cg%20transform%3D%22translate(-2666%20-5985)%22%20style%3D%22fill%3A%20%2300a22e%3B%20filter%3A%20url(%23filter)%22%3E%0A%20%20%20%20%3Cpath%20id%3D%22checkmark%22%20class%3D%22cls-1%22%20d%3D%22M2673.31%2C5997.34l-0.78-.81%2C7.76-8.09%2C0.78%2C0.81Zm-3.85-4.11%2C0.82-.78%2C3.81%2C4.02-0.82.78Z%22%20style%3D%22stroke%3A%20inherit%3B%20filter%3A%20none%3B%20fill%3A%20inherit%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%20%20%3Cuse%20transform%3D%22translate(-2666%20-5985)%22%20xlink%3Ahref%3D%22%23checkmark%22%20style%3D%22stroke%3A%20%23fff%3B%20filter%3A%20none%3B%20fill%3A%20none%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-size: 18px 16px;
  background-position: center;
  background-color: #008a00;
  color: transparent;
}

@-moz-document url-prefix() {
  .form-check input[type="radio"]:checked + label::before,
  .form-check input[type="checkbox"]:checked + label::before {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2219%22%20height%3D%2216%22%20viewBox%3D%220%200%2019%2016%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20fill%3A%20%2300a22e%3B%0A%20%20%20%20%20%20%20%20stroke%3A%20%23fff%3B%0A%20%20%20%20%20%20%20%20stroke-linejoin%3A%20round%3B%0A%20%20%20%20%20%20%20%20stroke-width%3A%202px%3B%0A%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%0A%20%20%20%20%20%20%20%20filter%3A%20url(%23filter)%3B%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%3C%2Fstyle%3E%0A%20%20%20%20%3Cfilter%20id%3D%22filter%22%20x%3D%222666%22%20y%3D%225985%22%20width%3D%2219%22%20height%3D%2216%22%20filterUnits%3D%22userSpaceOnUse%22%3E%0A%20%20%20%20%20%20%3CfeFlood%20result%3D%22flood%22%20flood-color%3D%22%23fff%22%2F%3E%0A%20%20%20%20%20%20%3CfeComposite%20result%3D%22composite%22%20operator%3D%22in%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%20%20%3CfeBlend%20result%3D%22blend%22%20in2%3D%22SourceGraphic%22%2F%3E%0A%20%20%20%20%3C%2Ffilter%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Cg%20transform%3D%22translate(-2666%20-5985)%22%20style%3D%22fill%3A%20%2300a22e%3B%20filter%3A%20url(%23filter)%22%3E%0A%20%20%20%20%3Cpath%20id%3D%22checkmark%22%20class%3D%22cls-1%22%20d%3D%22M2673.31%2C5997.34l-0.78-.81%2C7.76-8.09%2C0.78%2C0.81Zm-3.85-4.11%2C0.82-.78%2C3.81%2C4.02-0.82.78Z%22%20style%3D%22stroke%3A%20inherit%3B%20filter%3A%20none%3B%20fill%3A%20inherit%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%20%20%3Cuse%20transform%3D%22translate(-2666%20-5985)%22%20xlink%3Ahref%3D%22%23checkmark%22%20style%3D%22stroke%3A%20%23fff%3B%20filter%3A%20none%3B%20fill%3A%20none%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-size: auto;
    background-position: right 80% top 50%;
    background-color: #008a00;
  }
}

.form-check input[type="radio"]:focus,
.form-check input[type="checkbox"]:focus {
  outline: 1px dotted #12422b;
  outline-offset: 1px;
}

@-moz-document url-prefix() {
  .form-check input[type="radio"]:focus + label::before,
  .form-check input[type="checkbox"]:focus + label::before {
    outline: 1px dotted #12422b;
    outline-offset: 1px;
  }
}

.form-check input[type="radio"]:disabled,
.form-check input[type="checkbox"]:disabled {
  border-color: #cccccc;
  background-color: #f9f9f9;
  opacity: 1;
}

.form-check input[type="radio"]:disabled::-ms-check,
.form-check input[type="checkbox"]:disabled::-ms-check {
  border-color: #8c8c8c;
}

@-moz-document url-prefix() {
  .form-check input[type="radio"]:disabled + label::before,
  .form-check input[type="checkbox"]:disabled + label::before {
    border-color: #8c8c8c;
    background-color: #f9f9f9;
    cursor: not-allowed;
  }
}

fieldset[disabled] .form-check input[type="radio"]::-ms-check,
fieldset[disabled] .form-check input[type="checkbox"]::-ms-check {
  border-color: #8c8c8c;
}

.form-check input[type="checkbox"] {
  border-radius: 0;
}

.form-check input[type="radio"] {
  border-radius: 50%;
}

.form-check input[type="radio"]::-ms-check {
  border-radius: 50%;
}

@-moz-document url-prefix() {
  .form-check input[type="radio"] + label::before {
    border-radius: 50%;
  }
}

.form-check input[type="radio"]:checked {
  border-width: 5px;
  background: white;
}

.form-check input[type="radio"]:checked::-ms-check {
  border-width: 5px;
  background: white;
}

@-moz-document url-prefix() {
  .form-check input[type="radio"]:checked + label::before {
    border-width: 5px;
    background: white;
  }
}

.td-multi-single-select-buttons {
  margin-top: 5px;
}

.td-multi-single-select-buttons legend {
  margin-bottom: 10px;
}

.td-multi-single-select-buttons .td-multi-single-select-button-wrapper {
  display: inline-block;
  position: relative;
  float: left;
  padding: 0 20px 15px 0;
  margin: 0;
}

@media (max-width: 767.98px) {
  .td-multi-single-select-buttons .td-multi-single-select-button-wrapper {
    min-width: 100%;
    padding-right: 0;
  }
}

.td-multi-single-select-buttons input[type="radio"],
.td-multi-single-select-buttons input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.td-multi-single-select-buttons input[type="radio"]::-ms-check,
.td-multi-single-select-buttons input[type="checkbox"]::-ms-check {
  border: none;
  background: transparent;
}

.td-multi-single-select-buttons label {
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  float: left;
  cursor: pointer;
  background: #fff;
  text-align: center;
  border: 2px solid #038203;
  border-radius: 0px;
  padding: 4px 56px 6px 56px;
  margin: 0;
  -webkit-user-select: none;
  user-select: none;
  width: auto;
  min-width: 100%;
  height: 100%;
}

.td-multi-single-select-buttons label::before {
  border-radius: 0rem;
  border: 2px solid #008a00;
  bottom: -2px;
  content: '';
  display: block;
  left: -2px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: -2px;
  top: -2px;
  transition: all 150ms ease-in-out;
}

.td-multi-single-select-buttons input[type="radio"]:hover + label,
.td-multi-single-select-buttons input[type="checkbox"]:hover + label,
.td-multi-single-select-buttons input[type="radio"]:focus + label,
.td-multi-single-select-buttons input[type="checkbox"]:focus + label {
  background: #f9fcf4;
}

.td-multi-single-select-buttons input[type="radio"]:focus + label::before,
.td-multi-single-select-buttons input[type="checkbox"]:focus + label::before {
  bottom: 3px;
  left: 3px;
  opacity: 0.75;
  right: 3px;
  top: 3px;
}

.td-multi-single-select-buttons input[type="radio"]:checked:focus + label::before,
.td-multi-single-select-buttons input[type="checkbox"]:checked:focus + label::before {
  border-color: #fff;
}

.td-multi-single-select-buttons input[type="radio"]:checked + label,
.td-multi-single-select-buttons input[type="checkbox"]:checked + label {
  color: #fff;
  background: #038203;
}

.td-multi-single-select-buttons input[type="radio"]:disabled + label,
.td-multi-single-select-buttons input[type="checkbox"]:disabled + label {
  color: #cccccc;
  background-color: #f9f9f9;
  border-color: #cccccc;
}

.td-multi-single-select-buttons input[type="radio"]:disabled + label:hover,
.td-multi-single-select-buttons input[type="checkbox"]:disabled + label:hover {
  cursor: default;
}

.td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper {
  height: 42px;
  width: 50%;
  min-width: 50%;
  padding: 0;
}

.td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper label {
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0;
  border-right: none;
}

.td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper:first-child label {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper:last-child label {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 2px solid #038203;
}

legend {
  font-size: 0.9375rem;
  padding: 0px;
  display: block;
  margin-bottom: 0px;
  border: 0;
  color: #1c1c1c;
}

@media (min-width: 768px) {
  .form-inline label + .form-control,
  .form-inline label + .input-group,
  .form-inline label + .td-select {
    margin-top: 0;
  }
  .form-inline .form-check {
    vertical-align: baseline;
    margin-right: 25px;
    margin-left: 15px;
    padding-left: 20px;
  }
  .form-inline .form-check label {
    padding: 2px 0 0 15px;
  }
  .form-inline .form-check input[type="radio"],
  .form-inline .form-check input[type="checkbox"] {
    position: absolute;
  }
}

.form-control.is-invalid, .form-control:invalid {
  color: #ae1100;
  border-bottom-width: 3px;
  padding-bottom: 2px;
  border-color: #8c8c8c;
  border-bottom-color: #ae1100;
  background-image: none;
}

.form-control.is-invalid:hover, .form-control.is-invalid:focus, .form-control:invalid:hover, .form-control:invalid:focus {
  padding-bottom: 2px;
  border-bottom-color: #ae1100;
}

.form-control.is-invalid:focus, .form-control:invalid:focus {
  border-color: #8c8c8c;
  border-bottom-color: #ae1100;
  box-shadow: none;
  outline-style: dotted;
  outline-color: #038203;
  outline-width: 1px;
}

.form-check input[type="radio"]:not(:checked).is-invalid,
.form-check input[type="checkbox"]:not(:checked).is-invalid,
.form-check input[type="radio"]:not(:checked):invalid,
.form-check input[type="checkbox"]:not(:checked):invalid {
  border-color: #ae1100;
  background-color: #f7e8e9;
}

.form-check input[type="radio"]:not(:checked).is-invalid::-ms-check,
.form-check input[type="checkbox"]:not(:checked).is-invalid::-ms-check,
.form-check input[type="radio"]:not(:checked):invalid::-ms-check,
.form-check input[type="checkbox"]:not(:checked):invalid::-ms-check {
  border-color: #ae1100;
  background-color: #f7e8e9;
}

@-moz-document url-prefix() {
  .form-check input[type="radio"]:not(:checked).is-invalid + label::before,
  .form-check input[type="checkbox"]:not(:checked).is-invalid + label::before,
  .form-check input[type="radio"]:not(:checked):invalid + label::before,
  .form-check input[type="checkbox"]:not(:checked):invalid + label::before {
    border-color: #ae1100;
    background-color: #f7e8e9;
  }
}

.form-check input[type="radio"].is-invalid ~ .form-check-label,
.form-check input[type="checkbox"].is-invalid ~ .form-check-label,
.form-check input[type="radio"]:invalid ~ .form-check-label,
.form-check input[type="checkbox"]:invalid ~ .form-check-label {
  color: #1c1c1c;
}

.td-error, .invalid-feedback {
  color: #ae1100;
  font-size: 0.75rem;
  padding: 4px 0;
  margin-top: 0;
}

.td-error .td-error-symbol, .invalid-feedback .td-error-symbol {
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.td-error .td-error-symbol::before, .invalid-feedback .td-error-symbol::before {
  content: "! ";
}

.td-error {
  font-size: 0.8125rem;
  font-family: "TD Graphik Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.form-control[disabled],
fieldset[disabled] .form-control,
.form-check input[type="radio"]:disabled,
.form-check input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.td-text-disabled {
  color: #cccccc;
}

.td-notification, .td-notification-section {
  padding: 10px 0px 14px 0px;
}

.td-notification.bg-danger h3, .td-notification-section.bg-danger h3 {
  color: #ae1100;
}

.td-notification.bg-success h3, .td-notification-section.bg-success h3 {
  color: #1a5336;
}

.td-notification h3, .td-notification-section h3 {
  display: flex;
  align-items: flex-start;
  margin-top: 1em;
  margin-bottom: 1em;
}

.td-notification-section {
  padding: 10px 0px 14px 0px;
  margin: 0px;
}

.td-notification h3 > span.td-icon, .td-notification-section h3 > span.td-icon {
  font-size: 30px;
  margin-right: 16px;
}

/*# sourceMappingURL=td-standards.css.map */
/*! td-emerald-standards - 4.5.15 - 2022-04-08, 13:20:11 */