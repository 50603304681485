// mixin to make icon silent for screen reader(like JAWS) for pseudo element
// $iconName - use td icon varialble name
// Reference Link https://www.stefanjudis.com/today-i-learned/css-content-accepts-alternative-text/
@mixin setIconWithEmptyPlaceholder($iconName){
  content: $iconName;

  @supports (content: "x" / "y") {
    content: $iconName / "";
  }

  @supports(alt: "") {
    alt: "";
  }
}